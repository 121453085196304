/* global module, $ */

/**
 * Handle Recap block
 * @constructor
 */
function offerRecap(site) {
  'use strict';

  var els = {},
    isMobile,
    mq = window.matchMedia('(min-width: 62.5rem)');

  /**
   * Set up all event listeners
   * @method
  **/
  function bind() {

    // Media query
    mq.addListener(resize);
    resize();


    // Listen scroll
    $.subscribe('scroll', scroll);
  }

  function desktop() {
    els.target = document.querySelector('.offer-selection');
  }

  /**
   * DOM queries
   * @method
  **/
  function dom() {
    els.block = document.querySelector('.offer-recap');

    if (!els.block) {
      return;
    }

    els.recap = els.block.querySelector('.offer-recap-block');
    els.header = document.getElementsByTagName('nav')[0];
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init() {
    dom();

    if (!els.block) {
      return;
    }

    bind();
  }

  function mobile() {
    els.target = document.querySelector('main');
    if (document.querySelector('.datepicker-reservation-bar') != null) {
      els.datePicker = document.querySelector('.datepicker-reservation-bar');
      els.datePicker.classList.add('force-datepicker-fixedposition');
    }
  }

  function resize(e, data) {
    isMobile = !mq.matches;

    if (isMobile) {
      mobile();
      on();
    }
    else {
      desktop();
      off();
    }

    scroll('resize', {
      pos: data && data.pos ? data.pos : site.listener.getPos().pos,
      direction: 'up',
      delta: 0
    });
  }

  function on() {
    $(els.block).on('click.recap', '[data-recap-toggle]', toggle);
  }

  function off() {
    $(els.block).off('click.recap', '[data-recap-toggle]');
  }

  function scroll(e, data) {
    var scrollBottom = data.pos + $(window).height(),
      bounding = els.block.getBoundingClientRect(),
      posBottom = $(window).scrollTop() + bounding.bottom;


    if (isMobile) {
      var pushOffersHeight = $('.section.grey').height() + 50;
      scrollBottom = data.pos + $(window).height();
      posBottom = $(window).scrollTop() + bounding.bottom;
      //els.recap.append(els.datePicker);
      if (scrollBottom < (posBottom - pushOffersHeight)) {
        $('.offer-recap-block').css('bottom', '0px');
        els.recap.classList.add('fixed');
      }
      else {
        $('.offer-recap-block').css('bottom', pushOffersHeight + 'px');
        els.recap.classList.remove('fixed');
      }
    }
    else {
      var navHeight = els.header.offsetHeight,
        posTop = $(window).scrollTop() + bounding.top - navHeight;

      posBottom = $(window).scrollTop() + bounding.bottom + $(window).height() - navHeight - els.recap.offsetHeight;

      var scrollTop = data.pos;

      if (scrollBottom > posBottom) {
        els.recap.classList.remove('fixed');
        els.recap.classList.add('bottom');
      }
      else {
        els.recap.classList.remove('bottom');

        if (scrollTop > posTop) {
          els.recap.classList.add('fixed');
        }
        else {
          els.recap.classList.remove('fixed');
        }
      }
    }
  }

  function toggle(e) {
    e.preventDefault();
    e.stopPropagation();

    var button = e.currentTarget,
      id = button.getAttribute('data-recap-toggle'),
      target = document.getElementById(id),
      isOpen = button.classList.contains('on');


    button.classList.toggle('on');

    if (isOpen) {

      $(target)
        .stop()
        .slideUp(200, function () {
          target.classList.remove('on');
          target.removeAttribute('style');
        });
    }
    else {
      target.classList.add('on');
      target.classList.remove('on');

      $(target)
        .stop()
        .slideDown({
          duration: 200,
          complete: function () {
            target.classList.add('on');
            target.removeAttribute('style');
          }
        });
    }
  }

  $.subscribe('app.start', init);

  return {};
}

module.exports = offerRecap;
