/* global module, $ */

/**
 * Handle Input files
 * @constructor
 */
function FileImg(){
  'use strict';

  var els;

  function bind(){

    $( document ).on( 'change', '[data-file-img]', fileHandler );

  }

  function dom( wrapper ){
    wrapper = wrapper || document;

    els = [].slice.call( wrapper.querySelectorAll( '[data-file-img]' ));
  }

  function fileHandler( e ){
    var input = e.target,
      id = input.getAttribute( 'data-file-img' ),
      target = document.getElementById( id );

    if( !target ){
      return;
    }

    if( !input.value ){
      if( target.original ){
        target.src = target.original;
      }
      return;
    }

    if( !target.original ){
      target.original = target.src;
    }

    var reader  = new FileReader(),
      file = input.files[0];

    reader.addEventListener( 'load', function (){
      target.src = reader.result;
    });
    if( file ){
      reader.readAsDataURL( file );
    }

  }

  function init(){
    if( !window.FileReader ) {
      return;
    }
    dom();

    if( !els || !els.length ){
      return;
    }

    bind();
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = FileImg;
