

document.addEventListener('DOMContentLoaded', function() {
  //Fix Filter box for map page


  $('.form-filters-bt').on('click', function () {


    $('#form-filters-overlay').show();
    $('#form-filters-overlay').addClass('on');

  });

  $('#form-filters-overlay').on('click', function () {
    $(this).hide();
    $(this).removeClass('on');

    if ($('.form-filters-bt').hasClass('on')) {
      $('.form-filters-bt').removeClass('on');
    }

    if ($('.form-filters').hasClass('on')) {
      $('.form-filters').removeClass('on');
    }



  });
	
});
