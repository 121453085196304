/* global module, $ */

/**
 * Handle custom selects behaviour
 * @constructor
 */
function Selects(){
  'use strict';

  function bind(){
    $( document ).on( 'change', '.select select', change );
  }

  function change( e ){
    var select = e.target ? e.target : e,
      label = select.parentNode.querySelector( '.label' ),
      optIndex = select.selectedIndex,
      selected = select.querySelectorAll( 'option' )[ optIndex ];

    label.textContent = selected.textContent;
  }

  function create( dom ){
    var lists = [].slice.call( dom.querySelectorAll( '.select-list' ));

    lists.forEach( function( list ) {
      var select,
        options = [],
        label = document.createElement( 'span' ),
        wrapper = document.createElement( 'span' );

      // Init dom elements
      label.className = 'label';
      wrapper.className = 'select';
      wrapper.appendChild( label );

      // Create select options
      var links = [].slice.call( list.querySelectorAll( 'a' ));
      links.forEach( function( link ){
        var selected = '';
        if( link.classList.contains( 'selected' )){
          selected = ' selected';
          label.setAttribute("data-value", link.textContent);
        }
        options.push([ '<option value="', link.href, '"', selected, '>', link.textContent, '</option>' ].join( '' ));
      });

      // append options
      wrapper.insertAdjacentHTML( 'afterbegin', '<select>' + options.join( '' ) + '</select>' );
      select = wrapper.querySelector( 'select' );

      // Bind event
      $( select ).on( 'change', function( e ){
        var elt = e.target,
          optIndex = elt.selectedIndex,
          url = elt.querySelectorAll( 'option' )[ optIndex ].value;

        if ( url && url !== '' ) {
          window.location.href = url;
        }
      });

      // Append select
      if( list.classList.contains( 'duplicate' )){
        list.parentNode.appendChild( wrapper );
      }
      else {
        list.parentNode.replaceChild( wrapper, list );
      }
    });
  }

  function init(){
    bind();
    refresh();
  }

  function on( dom ){
    var selects = [].slice.call( dom.querySelectorAll( '.select select' ));

    if( !selects ){
      return;
    }

    selects.forEach( function( select ){
      // $( select ).on( 'change', change );

      // First load
      change( select );
    });
  }

  function refresh( dom ){
    dom = dom || document;

    create( dom );
    on( dom );
    // bind( dom );
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = Selects;
