/* global module, $, google */
require( '../../../../Common/scripts/tools/customwindow' );

/**
 * Handle Place Google maps
 * @constructor
 */
function placeMap( site ){
  'use strict';

  var els = {},
    map, bounds, markers = [],
    infoWindow,
    mobile,
    mq = window.matchMedia( '(min-width: 40rem)' );

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){

    // Media query
    mq.addListener( resize );
    resize();
  }

  function create(){
    function callback(){
      draw();
    }

    if( typeof google === 'undefined' || typeof google.maps === 'undefined' ){
      loadMapAPI();
      $.subscribe( 'gmap.load', function(){
        $.unsubscribe( 'gmap.load' );
        callback();
      });
    }
    else {
      callback();
    }
  }

  function createMarkers( place ){
    var img = {
      size: new google.maps.Size( 33, 44 ),
      origin: new google.maps.Point( 0,0 ),
      anchor: new google.maps.Point( 16.5, 44 ),
      scaledSize: new google.maps.Size( 33, 44 ),
      labelOrigin: new google.maps.Point( 0, 0)
    };

    bounds = new google.maps.LatLngBounds();

    // Create place marker
    var placePos = new google.maps.LatLng( +place.lat, +place.lng );
    bounds.extend( placePos );
    img.url = place.img;

    var placeMarker = new google.maps.Marker({
      clickable: false,
      icon: img,
      position: placePos,
      optimized: false,
      zIndex: 9999999,
      map: map
    });

    markers.push( placeMarker );

    if( !els.blocks.length ){
      return;
    }

    // Get all activities markers
    els.blocks.forEach( function( block ){
      var data = JSON.parse( block.getAttribute( 'data-place-marker' )),
        pos = new google.maps.LatLng( +data.lat, +data.lng );

      bounds.extend( pos );

      img.url = data.img;

      var marker = new google.maps.Marker({
        icon: img,
        position: pos,
        map: map
      });

      marker.addListener( 'click', function() {
        var div = document.createElement( 'div' ),
          node = block.cloneNode( true ),
          close = [ '<button class="map-info-close" type="button">',
                      '<svg aria-hidden="true" class="icon-close">',
                        '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-close"></use>',
                      '</svg>',
                      '<span>', site.l10n.gmap.close, '</span>',
                    '</button>' ].join( '' );

        node.removeAttribute( 'data-place-marker' );

        div.appendChild( node );
        div.insertAdjacentHTML( 'afterbegin', close );

        infoWindow.setContent( div.innerHTML );
        window.picturefill({
          elements: $( 'img', infoWindow.container )
        });

        window.setTimeout( function(){
          infoWindow.open( map, marker );
        }, 10 );

      });

      markers.push( marker );
    });
    //map.fitBounds( bounds );
    map.setZoom(15);
    map.setCenter(placePos);
  }

  /**
   * DOM queries
   * @method
  **/
  function dom(){
    els.container = document.querySelector( '[data-place-map]' );

    if( !els.container ){
      return;
    }

    els.map = els.container.querySelector( '.map' );
    els.blocks = [].slice.call(  els.container.querySelectorAll( '[data-place-marker]' ));
  }

  function draw(){

    var place = JSON.parse( els.container.getAttribute( 'data-place-map' )),
      options = {
        center: {
          lat: +place.lat,
          lng: +place.lng
        },
        zoom: 12,
        draggable: true,
        // panControl: false,
        mapTypeControl: false,
        overviewMapControl: false,
        rotateControl: false,
        scrollwheel: false,
        streetViewControl: false,
        styles: [
        {
            featureType: "poi",
            stylers: [
              { visibility: "off" }
            ]   
          }
        ],
        zoomControlOptions: {
          position: mobile ? google.maps.ControlPosition.RIGHT_BOTTOM : google.maps.ControlPosition.RIGHT_TOP
        }
      };
    map = new google.maps.Map( els.map, options );

    // Init infoWindow
    infoWindow = new (window.GenCustomWindow())();

    // Create Markers
    createMarkers( place );

  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();

    if( !els.container ){
      return;
    }

    bind();
    create();
  }

  /**
   * Loads Google maps API
   * @method
  **/
  function loadMapAPI(){
    window.gmapLoad = function() {
      $.publish( 'gmap' );
    };

    var script = document.createElement( 'script' );
    script.src = 'https://maps.google.com/maps/api/js?key='+ ( site.conf.gmap.key || '' ) +'&callback=gmapLoad';

    document.head.appendChild( script );
  }

  function resize(){
    mobile = !mq.matches;

    if( map ){
      map.setOptions({
        zoomControlOptions: {
          position: mobile ? google.maps.ControlPosition.RIGHT_BOTTOM : google.maps.ControlPosition.RIGHT_TOP
        }
      });
    }
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = placeMap;
