/* global module, $ */

/**
 * Handle to top navigation module behaviour
 * @constructor
 */
function ToTop(){
  'use strict';

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){
    $( document ).on( 'click', '.back-top', navigate );
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    bind();
  }

  /**
   * Handle smooth scroll
   * @method
   * @param {event} Click event
  **/
  function navigate( e ) {

    e.preventDefault();

    var target     = document.querySelector( e.currentTarget.hash ),
        posY       = target ? target.getBoundingClientRect().top : 0,
        currentPos = window.pageYOffset,
        newPos     = currentPos <= 0 ? posY : currentPos + posY;

    $( 'html, body' ).animate({
      scrollTop: newPos
    }, 650 );

  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = ToTop;
