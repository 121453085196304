/* global module, $ */
var Calendar = require( './calendar' );

/**
 * Handle events calendar
 * @constructor
 */
function Events( site ){
  'use strict';

  function dom(){
    var els = [].slice.call( document.querySelectorAll( '[data-calendar]' ));
    els.forEach( function( el ){
      var dates = [],
        range = [],
        attributes = el.attributes;

      if( attributes[ 'data-calendar-dates' ]){
        dates = attributes[ 'data-calendar-dates' ].value.split( ',' );
      }

      if( attributes[ 'data-calendar-range' ]){
        range = attributes[ 'data-calendar-range' ].value.split( ',' );
      }

      var l10n = site.l10n.calendar;

      new Calendar( el, {
        dates: dates,
        range: range,
        l10n: {
          firstDay: l10n.firstDay,
          days: l10n.days,
          months: l10n.months,
          previous: '<svg class="icon-prev" aria-hidden="true"><use xlink:href="#icon-prev"></svg><span>' + l10n.previous + '</span>',
          next: '<svg class="icon-prev" aria-hidden="true"><use xlink:href="#icon-prev"></svg><span>' + l10n.next + '</span>'
        }
      });
    });
  }

  function init(){
    dom();
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = Events;
