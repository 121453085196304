/* global module, $ */



/**
 * Handle offer search page
 * @constructor
 */
function BookingSearch(){
  'use strict';

  /**
   * Object to store DOM selectors
  **/
  var els = {},
    el,
    mobile,
    mq = window.matchMedia( '(min-width: 62.5rem)' );

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){

    // Media query
    mq.addListener( resize );
    resize();

    $( el ).on( 'click', '[data-show-form]', showForm );

    // Checkbox selection
    $( el )
      .on( 'click', '[data-selection-all]', function( e ){
        e.preventDefault();
        check( e.currentTarget, true );
      })
      .on( 'click', '[data-selection-none]', function( e ){
        e.preventDefault();
        check( e.currentTarget, false );
      });

    // Check all
    $( el )
      .on( 'change', '[data-check-all]', toggleCheckAll )
      .on( 'change', '[data-check-all-target] input[type="checkbox"]:not("[data-check-all]")', toggleCheck );

    // Invert fields
    $( el )
      .on( 'click', '[data-invert-toggle]', invert );

    // Check hide
    $( '[data-check-hide]', el ).each( function( i, input ){
      $( input ).on( 'change', function( e ){
        toggleCheckHide( e.currentTarget );
      });
      toggleCheckHide( input );

      if( input.type === 'radio' ){
        var name = input.name;
        $( 'input:radio[name="'+name+'"]:not([data-check-hide])', el ).on( 'change', function( e ){
          var checkName = e.currentTarget.name;
          $( '[data-check-hide][name="'+ checkName +'"]', el ).trigger( 'change' );
        });
      }

    });
    // $( el ).on( 'change', '[data-check-hide]', toggleCheckHide );

    $( document ).on( 'click', '[data-redirect]', function( e ){
      var url = e.currentTarget.getAttribute( 'data-redirect' );
      window.open( url, '_blank' );
    });
  }

  /**
   * Checks/unchecks all checkboxes
   * @param  {node} button - clicked element
   * @param  {boolean} [all] - check/uncheck parameter
   * @method
  **/
  function check( button, all ){
    var $target = $( button ).closest( '[data-selection-target]' );
    if( !$target.length ){
      return;
    }

    var $inputs = $( 'input[type="checkbox"]', $target );
    $inputs.each( function( i, elt ){
      if( all ){
        elt.checked = 'checked';
      }
      else {
        elt.checked = false;
        elt.removeAttribute( 'checked' );
      }
    });
  }

  /**
   * DOM queries
   * @method
  **/
  function dom(){
    el = document.getElementById( 'booking-search' );

    if( !el ){
      return;
    }

    els.form = el.querySelector( '.booking-form' );
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();

    if( !el ){
      return;
    }

    bind();
  }

  /**
   * Invert fields
   * @param  {event} e - click event
   * @method
  **/
  function invert( e ){
    e.preventDefault();
    var fields = [].slice.call( el.querySelectorAll( '[data-invert-field]' )),
      temp = [];

    fields.forEach( function( field ) {
      var input = field.querySelector( 'input[type="text"]' );

      if( input.classList.contains( 'autocomplete-init' )){
        $( input ).autocomplete().hide();
      }
      temp.push({
        value: input.value,
        icon: field.querySelector( 'svg' ),
        readonly: !!input.attributes[ 'readonly' ]
      });
    });

    fields.forEach( function( field, i ) {
      var idx = i === 0 ? fields.length - 1 : i - 1,
        input = field.querySelector( 'input[type="text"]' );

      input.value = temp[idx].value;

      input.readonly = false;
      input.removeAttribute( 'readonly' );
      if( temp[idx].readonly ){
        input.setAttribute( 'readonly', true );
      }

      var svg = field.querySelector( 'svg' );
      $( svg ).replaceWith( $( temp[idx].icon ).clone() );

      // Call custom event
      $.publish( 'input.refresh', input );

    });
  }

  /**
   * Close layer
   * @param  {event} e - click event
   * @method
  **/
  function layerClose( e ){
    var bt = e.currentTarget,
      $target = $( bt ).closest( '[data-target-layer]' );

    $target.slideUp({
      duration: 400,
      complete: function(){
        $target[0].classList.remove( 'on' );
        $target[0].removeAttribute( 'style' );
      }
    });
  }

  /**
   * Toggle Layer on mobile
   * @param  {event} e - click event
   * @method
  **/
  function layerToggle( e ){
    var bt = e.currentTarget,
      id = bt.getAttribute( 'data-toggle-layer' ),
      target = document.getElementById( id );

    if( target.classList.contains( 'on' )){
      $( target ).slideUp({
        duration: 400,
        complete: function(){
          target.classList.remove( 'on' );
          target.removeAttribute( 'style' );
        }
      });
    }
    else {
      $( target ).slideDown({
        duration: 400,
        complete: function(){
          target.classList.add( 'on' );
          target.removeAttribute( 'style' );
        }
      });

      $( 'html, body' ).animate({
        scrollTop: 0
      },
      {
        duration: 200
      });
    }
  }

   /**
   * Binds mobile events listener
   * @method
  **/
  function on(){
    $( el )
      .on( 'click.booking', '[data-toggle-layer]', layerToggle )
      .on( 'click.booking', '[data-close-layer]', layerClose );
  }

  /**
   * Unbinds mobile events listener
   * @method
  **/
  function off(){
    var layers = [].slice.call( el.querySelectorAll( '[data-target-layer].on' ));
    layers.forEach( function( layer ){
      layer.classList.remove( 'on' );
    });

    $( el )
      .off( 'click.booking', '[data-toggle-layer]' )
      .off( 'click.booking', '[data-close-layer]' );
  }

  /**
   * Trigger on resize and tests if it's on mobile layout.
   * @method
  **/
  function resize(){
    mobile = !mq.matches;

    if( mobile ){
      on();
    }
    else {
      off();
    }
  }

  /**
   * Shows booking form on click on recap block
   * @param  {event} e - click event
   * @method
  **/
  function showForm( e ){
    var $elt = $( e.target );

    if( $elt.is( 'button, a' ) || $elt.closest( 'button, a' ).length){
      e.preventDefault();
    }
    var $trigger = $elt.closest( '.change-fieldset' );
    $trigger
      .stop()
      .animate({
        opacity: 0
      },{
        duration: 400
      })
      .promise()
      .done( function(){

        var banner = el.querySelector( '.form-header-booking' );
        banner.style.minHeight = $( banner ).height() + 'px';

        $trigger.hide();

        $( els.form )
          .slideDown({
            duration: 600,
            complete: function(){
              banner.style.minHeight = '';
            }
          });

      });
  }

  /**
   * Toggle all checkboxes
   * @param  {event} e - change event
   * @method
  **/
  function toggleCheckAll( e ){
    var checkbox = e.currentTarget,
      $target = $( checkbox ).closest( '[data-check-all-target]' );

    if( !$target.length ){
      return;
    }

    var $inputs = $( 'input[type="checkbox"]:not("[data-check-all]")', $target );
    $inputs.each( function( i, elt ){
      elt.checked = false;
      elt.removeAttribute( 'checked' );
    });
  }

  /**
   * Show/hide on input radio/checkbox change
   * @param  {node} input - changed input
   * @method
  **/
  function toggleCheckHide( input ){
    var id = input.getAttribute( 'data-check-hide' ),
      $targets = $( '[data-check-hide-target="'+id+'"]' );

    if( !$targets.length ){
      return;
    }

    var $parent = $targets.closest( '.change-fieldset' );
    var $inputs = $( 'input', $targets );

    if( !!input.checked ){
      $targets.hide();
      $parent.addClass( 'small' );
      $inputs.each( function( i, elt ){
        if( elt.hasAttribute( 'data-required' ) ) {
          elt.removeAttribute( 'required' );
        }
      });
    }
    else {
      $targets.show();
      $parent.removeClass( 'small' );
      $inputs.each( function( i, elt ){
        if( elt.hasAttribute( 'data-required' ) ) {
          elt.setAttribute( 'required', 'required' );
        }
      });
    }
  }

  /**
   * Toggle checkboxes
   * @param  {event} e - change event
   * @method
  **/
  function toggleCheck( e ){
    var checkbox = e.currentTarget,
      $target = $( checkbox ).closest( '[data-check-all-target]' );

    if( !$target.length ){
      return;
    }

    var $all = $( "[data-check-all]", $target ),
      $inputs = $( 'input[type="checkbox"]:not("[data-check-all]")', $target );

    if( $inputs.length === $inputs.filter(':checked').length ) {
      $all[0].checked = 'checked';
    }
    else {
      $all[0].checked = false;
      $all[0].removeAttribute( 'checked' );
    }
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = BookingSearch;
