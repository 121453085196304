/* global module, $ */

/**
 * Handle display toggle on mobile devices
 * @constructor
 */
function Toggle(){
  'use strict';

  var els,
    mq = window.matchMedia( '(min-width: 40rem)' );

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){

    // Media query
    mq.addListener( resize );
    resize();
  }

  /**
   * DOM queries
   * @method
  **/
  function dom() {
    els = [].slice.call( document.querySelectorAll( '[data-toggle]' ));
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();

    if( !els || !els.length ){
      return;
    }

    bind();
  }

  function on(){
    els.forEach(  function( el ){
      $( el ).on( 'click.toggle', toggle );

      var $legend = $( el ).closest( '.legend, .offer-cat-head' );
      if( !$legend.length ){
        return;
      }
      $legend.on( 'click.toggle', function(){
        $( '[data-toggle]', this ).trigger( 'click' );
      });
    });
  }

  function off() {

    els.forEach(  function( el ){
      $( el ).off( 'click.toggle');

      var $legend = $( el ).closest( '.legend' );
      if( !$legend.length ){
        return;
      }
      $legend.off( 'click.toggle' );
    });
  }

  function resize(){
    var mobile = !mq.matches;

    if( mobile ){
      on();
    }
    else {
      off();
    }
  }

  function toggle( e ){
    e.preventDefault();
    e.stopPropagation();

    var button = e.currentTarget,
      id = button.getAttribute( 'data-toggle' ),
      target = document.getElementById( id ),
      isOpen = button.classList.contains( 'on' );


    button.classList.toggle( 'on' );

    if( isOpen ){

      $( target )
        .stop()
        .slideUp( 200, function(){
          target.classList.remove( 'on' );
          target.removeAttribute( 'style' );
        });
    }
    else {
      target.classList.add( 'on' );
      var sliders = [].slice.call( target.querySelectorAll( '.slick-slider' ));
      sliders.forEach( function( slider ){
        if( slider.slick ){
          slider.slick.refresh();
        }
      });
      target.classList.remove( 'on' );

      $( target )
        .stop()
        .slideDown({
          duration: 200,
          complete: function(){
            target.classList.add( 'on' );
            target.removeAttribute( 'style' );
          }
        });
    }

    // button.classList.toggle( 'on' );
    // document.getElementById( id ).classList.toggle( 'on' );


  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = Toggle;
