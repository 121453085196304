/* global module, $ */

/**
 * Handle Popins
 * @constructor
 */
function Popins(){
  'use strict';

  var els,
    root, doc,
    current, next, opener,
    opened;

  var savedScroll;

  // var container = function( params ){
  //   var html = [
  //     '<article class="', params.type, '">',
  //       '<div class="inner"></div>',
  //     '</article>'
  //   ];

  //   return html.join( '' );
  // };

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){

    // bind the close button
    els.$popin.on( 'click', '[data-popin-close]', close );

    // close on click on layer if not on mobile
    if( !Modernizr.touchevents ){
      els.$popin.on( 'click', 'article', close );
    }

    // listen for click on [data-popin]
    $( document ).on( 'click', '[data-popin]', open );

    // Subscribe to open custom event
    $.subscribe( 'popin.open', function( e, data ){
      open( data );
    });

    // Subscribe to close custom event
    $.subscribe( 'popin.close', close );
  }

  function callDone(){

  }

  function callFail(){

  }

  function callSuccess( res ){

    var temp = document.createElement( 'div' ),
      article;

    // add the response into a div to be able to parse it
    $( temp ).html( res );

    // get the article
    article = temp.querySelector( 'article' );
    var imgs = article.querySelectorAll( 'img[srcset], picture img' );
    imgs = [].slice.call( imgs ); // make it an array

    if( "video" === current.type ){
      els.video = article.querySelector( 'iframe' );
      els.video.dataSrc = els.video.getAttribute( 'src' );
      els.video.removeAttribute( 'src' );
    }

    els.popin.appendChild( article );

    // refresh srcset images
    if( imgs.length ){
      window.picturefill({
        elements: imgs
      });
    }

    // store data
    store( article );

    temp = null;
  }

  function close( e, callback ){

    // ensure click is on popin container or on close button
    if ( e && e.type !== "keyup" && e.currentTarget && e.currentTarget !== e.target && !$( e.currentTarget ).closest( '[data-popin-close]' ).length ) {
      return;
    }

    // handle `ESC` key to close
    if ( e && e.type === 'keyup' && e.keyCode !== 27 ) {
      return;
    }


    if( e && e.preventDefault ){
      e.preventDefault();
    }

    if( !current ) {
      return;
    }

    if( current && current.close ){
      current.close( els.popin, current );
    }

    // reverse the timelines
    reverse( callback );

    // popin is closed, so say it
    $.publish( 'popin.closing' );
  }

  function display(){

    // disable scroll
    doc.classList.add( 'layer-open' );

    // display the popin container so we can get the sizes of elements
    els.popin.style.display = 'block';

    // popin is opened, so say it
    $.publish( 'popin.opened', els.popin );

    play();

    var timeout = els.popin.querySelector( '[data-timeout-close]' );
    if( timeout ){
      // window.open( timeout.getAttribute( 'data-timeout' ), '_blank' );
      window.setTimeout( function(){
        close();
      }, +timeout.getAttribute( 'data-timeout-close' ));
    }

    if( next ){
      next = false;
      return;
    }
  }

  function displayed(){

    // bind ESC key
    $( document ).on( 'keyup.popin', close );

    if ( Modernizr.ios ) {
      savedScroll = doc.scrollTop || document.body.scrollTop;
      doc.style.position = 'fixed';
      doc.style.overflow = 'hidden';
      doc.style.top = [ -savedScroll, 'px' ].join('');
    }

    // popin status
    opened = true;

    // update the placeholder patch
    $( 'input, textarea' ).each( function( idx, el ){
      $( el ).trigger( 'blur' );
    });

    // focus the popin
    if( document.activeElement ){
      document.activeElement.blur();
    }

    els.focusables = els.popin.querySelectorAll( 'button, a[href], input, textarea, [tabindex]' );
    els.lastFocusable = els.focusables[ els.focusables.length -1 ];
    els.focused = els.focusables[ 0 ];

    if( els.focused ){
      els.focused.focus();
    }

    // set the iframe src when the animation is done
    if( els.video ){
      els.video.setAttribute( 'src', els.video.dataSrc );
    }

    if( current.ready ){
      current.ready( els.popin, current );
    }
  }

  /**
   * DOM queries
   * @method
  **/
  function dom(){
    if( els ){
      return;
    }
    // store the main wrapper where the popin will be written
    root = document.body;
    doc = document.documentElement;

    // create the elements
    var section = document.createElement( 'section' );
    section.setAttribute( 'id', 'popin');

    // store the references
    els = {
      popin: section,
      $popin: $( section )
    };
  }

  function fetch( params ){
    $.ajax({
      'url': params.url,
      'type': 'GET'
    })
    .done( callSuccess )
    .fail( callFail )
    .always( callDone );
  }

  function hidden(){
    // focus back the opener
    if( !next ){
      document.activeElement.blur();
      opener.focus();
      opener = false;
    }

    els.popin.classList.remove( 'center' );

    // if a new popin as to be opened
    if( next ){

      els.popin.innerHTML = "";

      current = null;

      open( next );
      return;
    }

    // popin status
    opened = false;

    current = null;

    // Publish custom event
    $.publish( 'popin.closed' );

    // empty the article of the popin once closed
    els.popin.innerHTML = '';

    // reset styles
    els.popin.style.display = 'none';
  }

  function hiding(){

    // enable scroll
    doc.classList.remove( 'layer-open' );

    // disable ESC key
    $( document ).off( 'keyup.popin' );

    if ( Modernizr.ios ) {
      doc.style.position = 'static';
      doc.style.overflow = 'visible';
      doc.scrollTop = document.body.scrollTop = savedScroll;
      doc.style.top = '';
    }

    if( current.closing ){
      current.closing();
    }
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){

    // generate the elements
    dom();

    // register the events
    bind();
  }

  /**
   * Opens popin
   * @method
  **/
  function open( params ){

    // test if we're trying to open a new popin when a popin is alreay displayed
    if( current ){
      next = $.extend( {}, params );

      reverse();
      if( params.preventDefault ){
        params.preventDefault();
        next.defaultPrevented = true;
      }
      return;
    }

    if( !opener ){
      // store the element that triggered the opening
      opener = document.activeElement;
    }

    // if params is an event
    if( params.currentTarget ){
      if( !params.defaultPrevented ){
        params.preventDefault();
      }
      var el = params.currentTarget,
        url = el.getAttribute( 'href' );

      // check if there's a data-layer attribute
      if( !url ){
        url = el.getAttribute( 'data-layer' );
      }

      params = {
        type: el.getAttribute( 'data-popin' ),
      };

      if( url ){
        params.url = url;
      }

      if( !params.url ){
        return;
      }
    }

    // store the params reference
    current = params;

    // if( !params.url && !params.html ){
    //   els.popin.innerHTML = container( params );
    // }

    // append to the dom
    root.appendChild( els.popin );

    if( params.url ){
      fetch( params );
    }
    else if ( params.html ){
      callSuccess( params.html );
    }
    else {
      store( els.popin.querySelector( '#popin > article' ));
    }

  }

  function play(){

    els.$popin
      .velocity(
        {
          zIndex: 20,
          opacity: 0,
          visibility: 'visible'
        },
        {
          duration: 0
        }
      )
      .velocity(
      {
        opacity: 1
      },
      {
        duration: 400,
        easing: 'ease-out',
        complete: displayed
      }
    );
  }

  function reverse( callback ){

    els.$popin.velocity(
      {
        opacity: 0
      },
      {
        duration: 200,
        easing: 'ease-in',
        begin: hiding,
        complete: function(){
          hidden();
          if( callback ){
            callback();
          }
        }
      }
    );
  }

  function store( article ){
    // store the reference
    els.article = article;

    display();
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = Popins;
