/* global module, $, google */
require( '../../../../Common/scripts/tools/customwindow' );
require( '../../../../Common/scripts/tools/markerwithlabel' );



/**
 * Handle offer search page
 * @constructor
 */
function OfferSearch( site ){
  'use strict';

  /**
   * Object to store DOM selectors
  **/
  var els = {},
    el,
    mobile,
    running,
    view,
    map, markers, infoWindow,
    center= {
      lat: 16.206651,
      lng: -61.570734
    },
    theme = "accommodation",
    mq = window.matchMedia( '(min-width: 62.5rem)' );

  /**
   * Object to store datas
   * @property {object} data - Default data
   * @property {number} data.delta - Scroll amount delta
   * @property {string} data.direction - Scroll direction `[up|down]
   * @property {number} data.pos - Scroll position in pixels
   * @property {object=} data.offers - offers parameters
  **/
  var data = {
    delta: 0,
    direction: 'down',
    pos: null,
    offers: null
  };

  var click = Modernizr.touchevents && Modernizr.ios ? 'touchend' : 'click';

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){

    // Media query
    mq.addListener( resize );
    resize();

    $.subscribe( 'window.load', function(){
      if( mobile ){
        scroll();
      }
    });

    // Fix label click bug on iOS
    if( Modernizr.ios ){
      $( el ).on( 'touchend', '.switch label', label );
    }
    $( el ).on( 'click touchend', '.switch label', function( e ){
      if( running || Modernizr.ios ){
        e.preventDefault();
        e.stopPropagation();
      }
    });


    // Manage view switch
    $( els.switch ).on( 'change', 'input[type="radio"]', function( e ){
      var input = e.currentTarget;
      if( !input.checked ){
        return;
      }
      changeView( input );
    });
    if( els.switch ){
     changeView( els.switch.querySelector( 'input:checked' ), true );
    }
  }

  /**
   * Manages view change
   * @param  {dom} input - radio input
   * @method
  **/
  function changeView( input, init ){
    if( !input || !input.checked ){
      return;
    }
    view = input.getAttribute( 'data-view' );

    // spécifique à la page d'hébergement / Activité / Restaurant
    if ((theme == 'accommodation') || (theme == 'activity') || (theme == 'restaurant')) {
      if (!mobile) {
        view = 'map';
      }
    }

    // Don't fade on load
    if( init ){
      theme = els.map.getAttribute( 'data-map-theme' );
      if((theme == 'accommodation' || theme == 'activity' || theme == 'restaurant') && !mobile){
        el.classList.add( 'map-view' );
      }
      else {
        el.classList.remove( 'map-view' );
      }

      switchView();

      // refresh sticky
      if( mobile ){
        scroll();
      }

      return;
    }

    var $elts = $( '.offer-results, .form-filters, .form-filters-bt:not(.on)', el );

    running = true;
    $elts
      .stop()
      .animate({
        opacity: 0
      },
      {
        duration: 400
      })
      .promise()
      .done( function(){
        if( view === 'map' ){
          el.classList.add( 'map-view' );
        }
        else {
          el.classList.remove( 'map-view' );
        }
        switchView();

        // refresh sticky
        if( mobile ){
          if( typeof window.requestAnimationFrame !== 'undefined' ){
            window.requestAnimationFrame( scroll );
          }
          else {
            window.setTimeout( scroll, 10 );
          }
        }

        if( view === 'map' ){
          var filtersBt = el.querySelector( '.form-filters-bt:not(.on)' );
          if( filtersBt ){
            filtersBt.style.opacity = 0;
          }
          $elts = $( '.offer-results, .form-filters.on, .form-filters-bt:not(.on)', el );
        }

        $elts
          .stop()
          .animate({
            opacity: 1
          },
          {
            duration: 400
          })
          .promise()
          .done( function(){
            var elts = [].slice.call( el.querySelectorAll( '.offer-results, .form-filters, .form-filters-bt' ));
            elts.forEach( function( elt ){
              elt.removeAttribute( 'style' );
            });
            running = false;
          });
      });
  }

  /**
   * Creates Google map
   * @method
  **/
  function createMap(){    

    // Get current theme
    theme = els.map.getAttribute( 'data-map-theme' ) || theme;
    
    var options = {
      center: {
        lat: 16.206651,
        lng: -61.570734
      },
      zoom: 10,
      draggable: true,
      // panControl: false,
      mapTypeControl: false,
      overviewMapControl: false,
      rotateControl: false,
      scrollwheel: false,
      streetViewControl: false,    
      zoomControlOptions: {
        position: (mobile || (theme == 'accommodation') || (theme == 'activity') || (theme == 'restaurant')) ? google.maps.ControlPosition.RIGHT_TOP : google.maps.ControlPosition.RIGHT_BOTTOM
      },
      fullscreenControl: ((theme == 'accommodation') || (theme == 'activity') || (theme == 'restaurant')) ? false : true,
      disableDefaultUI: ((theme == 'accommodation') || (theme == 'activity') || (theme == 'restaurant')) ? true : false
    };

    map = new google.maps.Map( els.map, options );

    // Init infoWindow
    infoWindow = new (window.GenCustomWindow())();

    

    // Get current theme
    //theme = els.map.getAttribute( 'data-map-theme' ) || theme;

    // Create Markers
    createMarkers();


    $( window ).on( 'resize', function(){
      if( view !== 'map' || !map ){
        return;
      }

      if( typeof window.requestAnimationFrame !== 'undefined' ){
        window.requestAnimationFrame( function(){
          map.setCenter( center );
        });
      }
      else {
        window.setTimeout( function(){
          map.setCenter( center );
        }, 10 );
      }
    });

    if ((theme == 'accommodation') || (theme == 'activity') || (theme == 'restaurant')) {
      var zoomControlDiv = document.createElement('div');
      var zoomControl = new ZoomControl(zoomControlDiv, map);
      zoomControlDiv.index = 1;
      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(zoomControlDiv);
    }
  }

  /**
   * Creates Zoom Control
   * @method
  **/
   function ZoomControl(div, map) {

    // Get the control DIV. We'll attach our control UI to this DIV.
    var controlDiv = div;
  
    // Set CSS for the controls.
    controlDiv.style.height = '100px';
    controlDiv.style.width = '50px';
    controlDiv.style.margin = '10px';
  
    var zoomin = document.createElement('div');
    zoomin.title = 'Click to zoom in';
    zoomin.style.display = "inline-block"
    zoomin.style.height = '50px';
    zoomin.style.width = '50px';
    zoomin.style.cursor = 'pointer';
    zoomin.style.backgroundImage = 'url(/Statics/B2C/img/ZoomIn.png)';
    controlDiv.appendChild(zoomin);

    var zoomout = document.createElement('div');
    zoomout.title = 'Click to zoom out';
    zoomout.style.display = "inline-block";
    zoomout.style.height = '50px';
    zoomout.style.width = '50px';
    zoomout.style.cursor = 'pointer';
    zoomout.style.backgroundImage = 'url(/Statics/B2C/img/ZoomOut.png)';
    controlDiv.appendChild(zoomout);

    // Setup the click event listeners for zoom-in, zoom-out:
    google.maps.event.addDomListener(zoomout, 'click', function() {
     var currentZoomLevel = map.getZoom();
     if(currentZoomLevel != 0){
       map.setZoom(currentZoomLevel - 1);}     
    });
  
     google.maps.event.addDomListener(zoomin, 'click', function() {
     var currentZoomLevel = map.getZoom();
     if(currentZoomLevel != 21){
       map.setZoom(currentZoomLevel + 1);}
    });
  }


  /**
   * Creates Google map markers
   * @method
  **/
  function createMarkers(){
    markers = [];

    var pict,
      image = {
        url: site.conf.offers.marker[ theme ],
        size: new google.maps.Size( site.conf.offers.marker.width / 2, site.conf.offers.marker.height / 2 ),
        origin: new google.maps.Point( 0,0 ),
        anchor: new google.maps.Point( site.conf.offers.marker.width / 4, site.conf.offers.marker.height / 2 ),
        scaledSize: new google.maps.Size( site.conf.offers.marker.width / 2, site.conf.offers.marker.height / 2 ),
        labelOrigin: new google.maps.Point( 0, 0)
      };

    if ((data != null) && (data.offers != null)) {  
      data.offers.forEach( function( offer ){
        var marker = new ( window.MarkerWithLabel())({
            position: new google.maps.LatLng( offer.gps[ 0 ], offer.gps[ 1 ]),
            icon: image,
            labelContent: offer.price,
            labelAnchor: new google.maps.Point( site.conf.offers.marker.width / 4, site.conf.offers.marker.height / 2 ),
            labelClass: ((theme == 'accommodation') || (theme == 'activity') || (theme == 'restaurant')) ? 'map-label white' : 'map-label',
            map: map, 
            selected: false
          });
        marker.id = offer.id;

        marker.addListener( 'click', function() {
          if ((theme != 'accommodation') && (theme != 'activity') && (theme != 'restaurant')) {
            var div = document.createElement( 'div' ),
              node = document.getElementById( offer.id ).querySelector( '.block-offer' ).cloneNode( true ),
              close = [ '<button class="map-info-close" type="button">',
                        '<svg aria-hidden="true" class="icon-close">',
                          '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-close"></use>',
                        '</svg>',
                        '<span>', site.l10n.gmap.close, '</span>',
                      '</button>' ].join( '' );

            node.removeAttribute( 'data-sorting-item' );
            node.removeAttribute( 'data-offer-map' );

            div.appendChild( node );
            div.insertAdjacentHTML( 'afterbegin', close );

            infoWindow.setContent( div.innerHTML );

            window.picturefill({
              elements: $( 'img', infoWindow.container )
            });
            infoWindow.open( map, marker );
          

          // markers.forEach( function( point ){
          //   if( !point.getVisible() ){
          //     point.setVisible( true );
          //   }
          // });

            marker.setVisible( false );
            marker.setIcon( image );
            marker.setOptions({
              labelClass: 'map-label'
            });
          }
          else {
            if (mobile) {
              els.smallOffers.slick.slickGoTo(parseInt(offer.id.replace('offer', '')));
            }
            else {
              // HCH : Set opacity to all offers
              var offersList = document.getElementById('results-list').querySelectorAll('li');
              offersList.forEach( function( elt ){
                elt.style.opacity = 0.5;
              });
              document.getElementById( offer.id ).style.opacity = 1;
            }  
            
            // remove all selected markers
            markers.forEach( function( elt ){
              elt.setIcon( image );
              elt.setOptions({
                labelClass: 'map-label white',
                selected: false
              });
            });
            
            pict = {
              url: site.conf.offers.marker.over2
            };
            pict = $.extend( {}, image, pict );
            marker.setIcon( pict );
            
            // set current selected marker
            marker.setOptions({
              labelClass: 'map-label-over',
              selected: true
            });
          }

          
        
        });

        if( !Modernizr.touchevents ){
          marker.addListener( 'mouseover', function() {
            pict = {
              url: ((theme == 'accommodation') || (theme == 'activity') || (theme == 'restaurant')) ? site.conf.offers.marker.over2 : site.conf.offers.marker.over
            };
            pict = $.extend( {}, image, pict );
            marker.setIcon( pict );
            marker.setOptions({
              labelClass: 'map-label-over'
            });
          });

          marker.addListener( 'mouseout', function() {
            if (((theme != 'accommodation') && (theme != 'activity') && (theme != 'restaurant')) || (((theme == 'accommodation') || (theme == 'activity') || (theme == 'restaurant')) && !this.selected)) {
              marker.setIcon( image );
              marker.setOptions({
                labelClass: ((theme == 'accommodation') || (theme == 'activity') || (theme == 'restaurant')) ? 'map-label white' : 'map-label'
              });
            }
          });
        }

        markers.push( marker );
      });
    }

    infoWindow.onRemove = function() {
      this.layer.removeChild( this.container );
      markers.forEach( function( point ){
        if( !point.getVisible() ){
          point.setVisible( true );
        }
      });
    };
  }

  /**
   * DOM queries
   * @method
  **/
  function dom() {
    el = document.getElementById( 'offer-search' );
    if( !el ){
      return;
    }
    els.layer = el.querySelector( '.form-layer' );
    els.switch = el.querySelector( '.form-display' );
    els.toggle = el.querySelector( '.form-filters-bt' );
    els.filters = el.querySelector( '.form-filters' );
    els.results = el.querySelector( '.offer-results' );
    els.map = el.querySelector( '.offer-map-block' );
    els.smallOffers = el.querySelector( '.small-block-offers' );
    els.offers = [].slice.call( el.querySelectorAll( '[data-offer-map]' ));
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();

    if( !el ){
      return;
    }

    // Store elements info
    store();

    bind();
  }

  /**
   * Prevents native label/input association on iOS to prevent scroll bug on fixed elements.
   * @param  {event} e - click event
   * @method
   *
  **/
  function label( e ){
    if( !Modernizr.ios ){
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    var label = e.currentTarget,
      id = label.getAttribute( 'for' ),
      input = document.getElementById( id );

    input.checked = true;
    $( input ).trigger( 'change' );
  }

  /**
   * Loads Google maps API
   * @method
  **/
  function loadMapAPI(){
    window.gmapLoad = function() {
      $.publish( 'gmap' );
    };

    var script = document.createElement( 'script' );
    script.src = 'https://maps.google.com/maps/api/js?key='+ ( site.conf.gmap.key || '' ) +'&callback=gmapLoad';
    script.id = 'GoogleMapAPI';

    if (!document.getElementById('GoogleMapAPI')) {
      document.head.appendChild( script );
    }
  }

  /**
   * Binds desktop events listener
   * @method
  **/
  function onDesktop(){
    // Navigation toggle
    $( el ).on( click+'.filters', '.form-filters-bt', toggleFilters );
  }

  /**
   * Binds mobile events listener
   * @method
  **/
  function onMobile(){

    $( el )
      // Toggle form layer
      .on( click+'.form', '[data-toggle-form], [data-toggle-form-label]', toggle );

    if( Modernizr.ios ){
      $( el ).on( 'click.form', '[data-toggle-form-label]', function( e ){
        e.preventDefault();
      });
    }

    // Window scroll listener
    if( els.switch ){
      $( document ).on( 'scroll.form', scroll );
      scroll();
    }

  }

  /**
   * Unbinds desktop events listener
   * @method
  **/
  function offDesktop(){

  }

  /**
   * Unbinds mobile events listener
   * @method
  **/
  function offMobile(){

    $( el )
      // Toggle form layer
      .off( click+'.form', '[data-toggle-form], [data-toggle-form-label]' );

    if( Modernizr.ios ){
      $( el ).off( 'click.form', '[data-toggle-form-label]' );
    }

    // Window scroll listener
    if( els.switch ){
      $( document ).off( 'scroll.form' );
    }

  }

  /**
   * Trigger on resize and tests if it's on mobile layout.
   * @method
  **/
  function resize(){
    mobile = !mq.matches;

    if( mobile ){
      onMobile();
      offDesktop();
    }
    else {
      onDesktop();
      offMobile();
    }

    if( map ){
      map.setOptions({
        zoomControlOptions: {
          position: mobile ? google.maps.ControlPosition.RIGHT_TOP : google.maps.ControlPosition.RIGHT_BOTTOM
        }
      });
    }
  }

  /**
   * Handles scroll event and stores position.
   * @method
  **/
  function scroll(){

    if( !els.switch ){
      return;
    }

    storeScroll();

    // Manage Sticky element
    sticky();
  }

  /**
   * Manages sticky element.
   * @method
  **/
  function sticky(){
    var bounding = el.getBoundingClientRect();

    if( bounding.height + el.offsetTop > data.pos + window.innerHeight ){
      if( !els.switch.classList.contains( 'sticky' )){
        els.switch.classList.add( 'sticky' );
      }
      
      if (els.smallOffers != null) {
        if( !els.smallOffers.classList.contains( 'sticky' )){
          els.smallOffers.classList.add( 'sticky' );
        }
      }
    }
    else {
      els.switch.classList.remove( 'sticky' );
      if (els.smallOffers != null) els.smallOffers.classList.remove( 'sticky' );
    }
  }

  /**
   *
   * Stores elements map info
   * @method
  **/
  function store(){
    els.offers.forEach( function( offer ){
      data.offers = data.offers || [];
      var params = offer.getAttribute( 'data-offer-map' );
      params = JSON.parse( params );
      params.id = offer.parentNode.id;

      if( params && params !== '' ){
        data.offers.push( params );
      }
    });
  }

  /**
   *
   * Stores scroll position.
   * @method
  **/
  function storeScroll(){
    var pos = document.documentElement.scrollTop || document.body.scrollTop,
      direction = 'down';

    if( data.pos === pos ){
      return;
    }

    if( data.pos > pos ){
      direction = 'up';
    }

    if( direction === data.direction ){
      data.delta += Math.abs( data.pos - pos );
    }
    else {
      data.delta = 0;
    }

    // Store position
    data.direction = direction;
    data.pos = pos;
  }

  /**
   * Manage view changing
   * @method
  **/
  function switchView(){
    function callback(){
      createMap();
    }

    // Map mode
    if( view === 'map' ){

      if( mobile ){
        $( document ).scrollTop( 0 );
      }

      if( map ){
        google.maps.event.trigger( map,'resize' );
        map.setCenter( center );
        return;
      }

      if( typeof google === 'undefined' || typeof google.maps === 'undefined' ){
        loadMapAPI();
        $.subscribe( 'gmap.load', function(){
          $.unsubscribe( 'gmap.load' );
          callback();
        });
      }
      else {
        callback();
      }

      if ((theme == 'accommodation') || (theme == 'activity') || (theme == 'restaurant')) {
        if (mobile) {
          $('#slick-slider').slick('refresh');
        }
      }
    }
  }

  /**
   * Toggles form display on mobile devices.
   * @param  {event} e - click event
   * @method
  **/
  function toggle( e ){
    e.preventDefault();

    if( running ){
      return;
    }
    running = true;
    var isOpen = els.layer.classList.contains( 'on' ),
      heightStart, heightEnd;

      heightStart = els.layer.offsetHeight;

      if( isOpen ){
        els.layer.classList.remove( 'on' );
      }
      else {
        els.layer.classList.add( 'on' );
      }

      heightEnd = els.layer.offsetHeight;
      if( isOpen ){
        els.layer.classList.add( 'on' );
      }

      els.layer.style.height = heightStart+'px';
      els.layer.style.overflow = 'hidden';

      $( els.layer ).animate({
        height: heightEnd
      },
      {
        duration: 400,
        complete: function(){
          running = false;
          els.layer.removeAttribute( 'style' );
          if( isOpen ){
            els.layer.classList.remove( 'on' );
          }
        }
      });
  }

  /**
   * Toggles filters display on map view.
   * @method
  **/
  function toggleFilters(){
    if( els.filters.classList.contains( 'on' )){
      return;
    }
    $( els.filters ).slideDown( 300, function(){
      els.filters.classList.add( 'on' );
      els.toggle.classList.add( 'on' );
      els.filters.removeAttribute( 'style' );
    });
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = OfferSearch;
