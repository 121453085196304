/* global module, $ */
var Calendar = require('./calendar'),
  moment = require('../tools/moment');

/**
 * Handle Calendar sliders
 * @constructor
 */
function calendarSlider(site) {
  'use strict';

  var els = {};

  /**
   * Creates Calendar and add slider
   * @method
   *
   * @param  {node} container - DOM container element
   * @param  {object} data - Calendar data
   **/
  function create(container, data) {
    // Do nothing if already initialized
    if (!data) {
      return;
    }

    var end = moment(data.range[1]),
      start = moment(data.range[0]),
      init = data.start,
      diff = end.diff(start, 'months'),
      firstSlide;


    // Test if values are valid
    if (!start.isValid() || !end.isValid()) {
      return;
    }

    if (init) {
      init = moment(init);
    }

    // Create slider
    var slider = document.createElement('div');

    // Create calendars
    for (var i = 0; i <= diff; i++) {
      if (i !== 0) {
        start.add(1, 'month');
      }

      var month = start.format('YYYY-MM'),
        highlights = data.availability[month] || (data.availability.filter(function(m) {
          return m.id === month;
        })[0] ? data.availability.filter(function(m) {
          return m.id === month;
        })[0].days : undefined),
        dates = [];

      if (highlights) {
        for (var j = 0, len = highlights.length; j < len; j++) {
          dates.push(highlights[j].date);
        }
      }


      var slide = document.createElement('div');
      slide.className = 'slide';

      var calendar = document.createElement('div');
      calendar.className = 'calendar';

      new Calendar(calendar, {
        dates: highlights ||  [],
        range: [month, month],
        l10n: {
          firstDay: site.l10n.calendar.firstDay,
          days: site.l10n.calendar.days,
          months: site.l10n.calendar.months,
          previous: '<svg class="icon-prev" aria-hidden="true"><use xlink:href="#icon-prev"></svg><span>' + site.l10n.calendar.previous + '</span>',
          next: '<svg class="icon-prev" aria-hidden="true"><use xlink:href="#icon-prev"></svg><span>' + site.l10n.calendar.next + '</span>'
        }
      });

      slide.appendChild(calendar);
      slider.appendChild(slide);

      if (start.isSame(init)) {
        firstSlide = [].indexOf.call(slider.childNodes, slide);
      }
    }

    container.appendChild(slider);

    // Init slider
    var options = {
      infinite: false,
      initialSlide: firstSlide ||  0,
      dots: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      prevArrow: [
        '<button type="button" class="slick-prev">',
        '<svg class="icon-prev-light" aria-hidden="true"><use xlink:href="#icon-prev-light"></svg>',
        '<span>', site.l10n.slider.previous, '<span>',
        '</button>'
      ].join(''),
      nextArrow: [
        '<button type="button" class="slick-next">',
        '<svg class="icon-prev-light" aria-hidden="true"><use xlink:href="#icon-prev-light"></svg>',
        '<span>', site.l10n.slider.next, '<span>',
        '</button>'
      ].join(''),
      responsive: [{
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    };

    // Init slider
    $(slider).slick(options);

    var slideDirection = "forward";

    $(slider).on('beforeChange', function(event, slick, currentSlide, nextSlide){
      if(nextSlide<currentSlide) {
        slideDirection = "back";
      }
    });
    $(slider).on('afterChange', function(event, slick, currentSlide){

      var monthAfterChange = $(".slick-active").first().find(".calendar-title").attr("data-month");
      if(slideDirection === "back") {
        monthAfterChange = $(".slick-active").first().find(".calendar-title").attr("data-month");
      }
      $.publish('calendarSlider.afterChange', {
        start : monthAfterChange
      });
    });

    // Add initialized classname
    container.classList.add('initialized');

  }

  /**
   * DOM queries
   * @method
   **/
  function dom() {
    els.containers = [].slice.call(document.querySelectorAll('[data-calendar-slider]'));

    if (!els.containers ||  !els.containers.length) {
      return;
    }
  }

  /**
   * Inits the module with necessary functions
   * @method
   **/
  function init() {
    dom();

    if (!els.containers ||  !els.containers.length) {
      return;
    }

    load();
  }

  /**
   * Loads calendar data passing data parameters or using XHR
   * @method
   **/
  function load(list, data, forcedRefresh) {
    var elts = list ||  els.containers;
    elts.forEach(function(container) {
      if(container != null){
        if (container.classList.contains('initialized') && forcedRefresh === true) {
          //container.remove();
          while (container.firstChild) {
            container.removeChild(container.firstChild);
          }
        } else if(container.classList.contains('initialized')) {
          return;
        }


        if (data) {
          create(container, data);
        } else {
          var url = container.getAttribute('data-calendar-slider');

          var params = {
            'url': url,
            'type': 'GET'
          };

          $.ajax(params)
            .done(function(data) {
              create(container, data);
            });
        }
      }
    });
  }

  /**
   * Parses given wrapper and inits sliders in it
   * @method
   *
   * @param {node} [wrapper] - Changed DOM element
   * @param {object} [data] - Calendar data
   **/
  function refresh(wrapper, data) {
    //var elt = wrapper ||  document;
    var containers = [];
    if(typeof data.offerID !== "undefined") {
        containers.push(document.getElementById('calendar-slider-'+data.offerID));
    } else {
        containers = [].slice.call( document.querySelectorAll('[data-calendar-slider]'));
    }
    load(containers, data, true);
  }

  $.subscribe('app.start', init);
  $.subscribe('calendarSlider.refresh', refresh);

  return {
    create: create,
    refresh: refresh
  };
}

module.exports = calendarSlider;
