
$(document).ready(function () {
  var pathname = window.location.pathname; // Returns path only
  //var url = window.location.href;     // Returns full URL
  //var origin = window.location.origin;   // Returns base URL

  //console.log('path:' + pathname + ', url: ' + url + ', origin: '+ origin );

  var current_view = ReadCookie('view-mode');

  if (current_view != null) {
    //console.log('current view: ' + current_view);
      if (current_view == 'map') {
        $('input[name=view-mode]').trigger('click');
      }
  } else {
    //console.log("current view: default" );
  }
  

  if ($('#reservetype-0').attr('checked') != 'checked') { $('.date-part').hide(); }

			
            $('.reservetype_cb').click(function () {

              var parent_class = $(this).parent();
              
              
              
             

              if ($(parent_class).children('input:nth-of-type(2)').attr('checked') != 'checked') {
                if ($(parent_class).children('input').hasClass('clicked')) {
                  $(parent_class).children('input').removeClass('clicked');
                  $(parent_class).children('label').children('svg').css('opacity', '0');
                  $(parent_class).children('input').removeAttr('checked');
                } else {

                  $(parent_class).children('input').addClass('clicked');
                  $('.reserve-type-part .block-checkbox').children('label').children('svg').css('opacity', '0');
                  $(parent_class).children('label').children('svg').css('opacity', '1');


                }
              } else {

                $(parent_class).children('input').removeClass('clicked');  
                $(parent_class).children('label').children('svg').css('opacity', '0');
                $('.date-part').hide();
                $(this).removeClass('date_expanded');

                setTimeout(function () { $(parent_class).children('input:nth-of-type(2)').removeAttr("checked"); }, 100);
              }



            });
			

   $('.reserve-type-part .block-checkbox:first-of-type input').click(function () {
     $('.reserve-type-part .block-checkbox:nth-of-type(2) input').removeAttr("checked");
     $('.reserve-type-part .block-checkbox:nth-of-type(2) input').removeClass('clicked');

     /*if ($(this).attr('checked') && !$(this).hasClass('date_expanded')) {
       $('.date-part').show();
       $(this).addClass('date_expanded');
     }*/
     if ($(this).attr('checked') != 'checked') {
       if ($(this).hasClass('date_expanded')) {
         $('.date-part').hide();
         $(this).removeClass('date_expanded');
       } else {
         $('.date-part').show();
         $(this).addClass('date_expanded');
       }
     }
			});
			
			$('.reserve-type-part .block-checkbox:nth-of-type(2) input').click(function(){				
        $('.date-part').hide();
        $('.reserve-type-part .block-checkbox:first-of-type input').removeAttr("checked");
        $('.reserve-type-part .block-checkbox:first-of-type input').removeClass('date_expanded');
        $('.reserve-type-part .block-checkbox:first-of-type input').removeClass('clicked');
              
      });


			
			$('.reserve-type-part button').click(function(){
				
				if ($(this).hasClass('on')){
						$('.date-part').hide();
				}else{

					
				}
				
			});


  $('input[name=view-mode]').on('click', function () {
    //var viewMode = $('input[name=view-mode]:checked').attr('data-view');
    var viewMode = GetData('input[name=view-mode]:checked');
    EraseCookie('view-mode');
    CreateCookie('view-mode', viewMode,  1, pathname);
  })



			
});

function GetData(selector) {
  var el = document.querySelector(selector);
  var view = el.getAttribute('data-view');

  return view;
}


function CreateCookie(name,
  value, days, path) {
  if (days) {
    var date = new Date();
    date
      .setTime(date.getTime()
        + (days * 24 * 60 * 60 * 1000));
    var expires = "; expires="
      + date.toGMTString();
  } else
    var expires = "";

  document.cookie = name + "="
    + value + expires
    + "; path=" + path;

}

function ReadCookie(name) {
  // retrieve
  // var con =
  // is_cookie('chat_conversations');
  // conv = jQuery.parseJSON(con);

  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ')
      c = c
        .substring(1,
          c.length);
    if (c.indexOf(nameEQ) == 0)
      return c.substring(
        nameEQ.length,
        c.length);
  }
  return null;
}
function EraseCookie(name) {
  CreateCookie(name, "", -1);
}
