/* global module, $ */


/**
 * Handle search
 * @constructor
 */
function BookingSearch(){
  'use strict';

  var wrapper,
    els = {},
    mobile,
    data = {
      mq: window.matchMedia('(min-width: 62.5rem)'),
      pos: null,
      delta: 0,
      direction: 'down',
      navTop: 0
    };
  /**
   * Object to store DOM selectors
  **/
  var el,
    els;

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){

    // Tab collapsing
    if( el ){
      $( el ).on( 'click', '[aria-expanded="false"]', trigger );
    }

    // Toggle kids part
    if( els.toggler.length ){
      $( document ).on( 'change', '[data-toggle-kids]', toggleKids );
      els.toggler.forEach( function( elt ){
        $( elt ).trigger( 'change' );
      });
    }

  }

  /**
   * Close opened tab
   * @method
   * @param {node} opened - element to close
   * @param {node} [next] - element to close on callback
  **/
  function close( opened, next ){
    var $target = $( document.getElementById( opened.getAttribute( 'aria-controls' ) ));

    opened.setAttribute( 'aria-expanded', 'false' );
    $target.slideUp( 300, function(){
      $target[0].setAttribute( 'aria-hidden', 'true' );
      if( next ){
        open( next );
      }
    });
  }

  /**
   * DOM queries
   * @method
  **/
  function dom(){
    el = document.querySelector( '[data-search]' );

    // if( !el ){
    //   return;
    // }

    els = {
      toggler: [].slice.call( document.querySelectorAll( '[data-toggle-kids]' ))
    };
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();
    mobile = !data.mq.matches;


    if( !el && !els.toggler.length ){
      return;
    }

    bind();
  }

  /**
   * Open tab
   * @method
  **/
  function open( button ){
    var $target = $( document.getElementById( button.getAttribute( 'aria-controls' ) ));

    button.setAttribute( 'aria-expanded', 'true' );
    $target.slideDown( {
      start: function () {
        if(mobile){
          $(this).css({
            display: "block"
          });
        }
        else{
          $(this).css({
            display: "flex"
          });
        }
        $target[0].setAttribute( 'aria-hidden', 'false' );
      }
    });
  }

  /**
   * Trigger tabs display
   * @method
   * @param {event} e - click event
  **/
  function trigger( e ){
    var opened = $( '[aria-expanded="true"]', el ),
      next = e.currentTarget;
    if( opened.length ){
      close( opened[0], next );
    }
    else {
      open( next );
    }
  }

  /**
   * Toggle kids part on select change
   * @method
   * @param {event} e - change event
  **/
  function toggleKids( e ){
    var select = e.currentTarget,
      value = +$( 'option:selected', select ).val(),
      target = document.getElementById( select.getAttribute( 'data-toggle-kids' ));

    if( isNaN( value ) || value <= 0 ){
      // close
      $( target ).slideUp( 300, function(){
        target.setAttribute( 'aria-hidden', 'true' );
        $( 'select', target ).each( function( idx, item ){
          item.selectedIndex = 0;
          $( item ).trigger( 'change' );
        });
      });
    }
    else {

      $( 'select', target ).each( function( idx, item ){
        if( idx < value ){
          $( item ).closest( '.select-block' ).addClass( 'visible' );
        }
        else {
          $( item ).closest( '.select-block' ).removeClass( 'visible' );
          item.selectedIndex = 0;
          $( item ).trigger( 'change' );
        }
      });

      if(  target.getAttribute( 'aria-hidden' ) === 'true' ){

        //open
        $( target ).slideDown( 300, function(){
          target.setAttribute( 'aria-hidden', 'false' );
        });
      }

    }
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = BookingSearch;
