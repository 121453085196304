/* global module, $ */

/**
 * Handle contacts behaviour
 * @constructor
 */
function LoadMore(){
  'use strict';

  var els;

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){
    els.forEach( function( el ) {
      var limit = el.getAttribute('data-more-limit');
      hideOffLimit(el, limit);
    });
  }

  /**
   * DOM queries
   * @method
  **/
  function dom() {
    els = document.querySelectorAll( '.load-more' );
    els = [].slice.call( els );
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();
    bind();
  }

  /**
   * Create a more button and append it to the given element
   * @method
  **/
  function createMoreButton( el ) {
    var button = document.createElement('button'),
      text = el.getAttribute('data-more-text');

    button.innerHTML = text;
    button.setAttribute('class','load-more-button link');
    return el.parentNode.insertBefore(button, el.nextSibling);
  }

  /**
   * Toggle associated block
   * @method
  **/
  function initMoreHandler( el ) {
    el.addEventListener( 'click', function() {
      var previousElChildren = el.previousSibling.children;

      for (var i = 0; i <	previousElChildren.length; i++) {
         	if(previousElChildren[i].style.display === "none") {
              previousElChildren[i].style.removeProperty('display');
          }
      }

      el.remove();
    }, false );
  }

  /**
   * Hide the inner elements superior to the limit provided
   * @method
  **/
  function hideOffLimit(el, limit) {
    if (el.hasChildNodes()) {
      var children = el.children;

      if( children.length <= limit ){
        return;
      }

      for (var i = 0; i < children.length; i++) {
        if(i >= limit) {
          children[i].style.display = "none";
        }
      }

      var btn = createMoreButton(el);
      initMoreHandler(btn);
    }
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = LoadMore;
