/* global module, $ */
require( '../tools/slick.js' );

/**
 * Handle custom selects behaviour
 * @constructor
 */
function Sliders( site ){
  'use strict';

  function create( slider ){
    var type = slider.getAttribute( 'data-slider' );

    var options = {
      infinite: true,
      dots: true,
      prevArrow: [
        '<button type="button" class="slick-prev">',
          '<svg class="icon-prev-light" aria-hidden="true"><use xlink:href="#icon-prev', type === 'offers' ? '' : '-light','"></svg>',
          '<span>', site.l10n.slider.previous, '<span>',
        '</button>'
      ].join( '' ),
      nextArrow: [
        '<button type="button" class="slick-next">',
          '<svg class="icon-prev-light" aria-hidden="true"><use xlink:href="#icon-prev', type === 'offers' ? '' : '-light','"></svg>',
          '<span>', site.l10n.slider.next, '<span>',
        '</button>'
      ].join( '' )
    };

    switch ( type ){

      // Logo slider
      case 'logo':
        $.extend( true, options, {
          dots: false,
          slidesToShow: 4,
          slidesToScroll: 4,
          responsive: [
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }
          ]
        });
        break;

      // icon slider
      case 'agencies':
        $.extend( true, options, {
          dots: false,
          slidesToShow: 5,
          slidesToScroll: 5,
          responsive: [
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
        });
        break;

      // icon slider
      case 'partners':
        $.extend( true, options, {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          responsive: [
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 640,
              settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
        break;
      case 'offers':
        $.extend( true, options, {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        });
        break;

      case 'small-offers':
        $.extend( true, options, {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 2
              }
            },
            {
              breakpoint: 640,
              settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 1
              }
            }
          ]
        });
        break;

      case 'simple':
        $.extend( true, options, {
          dots: false
        });
        break;
    }

    $( slider ).slick( options );
  }

  function dom( wrapper ){
    wrapper = wrapper || document;

    var sliders = [].slice.call( wrapper.querySelectorAll( '[data-slider]' ));

    sliders.forEach( function( slider ){
      if( slider.classList.contains( 'slick-initialized' )) {
        return;
      }

      if( $( slider ).closest( '[aria-hidden="true"]' ).length ){
        return;
      }

      // els.sliders.push( slider );
      create( slider );
    });
  }

  function init(){
    dom();

    $.subscribe( 'popin.opened', function( e, popin ){
      dom( popin );
    });
  }

  $.subscribe( 'document.load', init );

  return{
    dom: dom
  };
}

module.exports = Sliders;
