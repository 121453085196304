/* global module, $ */
var noUiSlider = require( '../tools/nouislider' ),
  moment = require( '../tools/moment' );

/**
 * Handle date pickers
 * @constructor
 */
function RangeSlider( site ){
  'use strict';

  var els;

  /**
   * Create Range sliders
   * @method
  **/
  function create( el ){
    var slider = el.querySelector( '[data-range-slider]' ),
      min = el.querySelector( '[data-range-min]' ),
      max = el.querySelector( '[data-range-max]' ),
      type = slider.getAttribute( 'data-range-slider' ) || 'currency',
      unit = site.l10n.range[ type ] ? site.l10n.range[ type ].unit : '';

    noUiSlider.create( slider, {
      start: [ min.value, max.value ],
      format: {
        to: function ( value ) {
          var result = Math.round( value );
          if( site.l10n.range && site.l10n.range[ type ] ){
            result = site.l10n.range[ type ].tooltip.replace( '{{VALUE}}', Math.round( value ));
          }
          else if ( type === 'time' ){
            result = moment().startOf( 'day' ).add( value, 'hours' ).format( 'HH:mm' );
          }
          return result;
        },
        from: function ( value ) {
          var currency = unit;
          return value.replace( currency, '');
        }
      },
      tooltips: [ true, true ],
      connect: true,
      step: +min.getAttribute( 'step' ),
      range: {
        'min': +min.getAttribute( 'min' ),
        'max': +max.getAttribute( 'max' )
      }
    });

    slider.noUiSlider.on( 'update', function( values, handle ) {
      var val = parseInt( values[ handle ] );

      if ( handle ) {
        max.value = val;
      }
      else {
        min.value = val;
      }
    });
  }

  /**
   * DOM queries
   * @method
  **/
  function dom() {
    els = [].slice.call( document.querySelectorAll( '[data-range]' ));

    els.forEach( function( el ){
      create( el );
    });
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = RangeSlider;
