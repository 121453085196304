/* global module, $ */

/**
 * Handle Sorting behavior
 * @constructor
 */
function Filter(){
  'use strict';

  var els = {},
    data = [],
    visible = [],
    full = false;

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){

    $( document ).on( 'submit', '[data-sort-form]', sort );
    $( els.form ).on( 'change', 'input[type="checkbox"]', sort );

    $( document ).on( 'click', '[data-sort-more]', toggle );
  }

  function display(){
    var ids = [],
      count = 0;
    visible.forEach( function( item ){
      ids.push( item.id );
    });

    els.items.forEach( function( node ){
      if( ids.indexOf( node.id ) === -1 || ( !full && count >= 6 )){
        node.classList.add( 'hide' );
      }
      else {
        node.classList.remove( 'hide' );
        ++count;
      }
    });

    if( ids.length < 7 ){
      els.more.style.display = 'none';
    }
    else {
      els.more.style.display = 'block';
    }
  }

  /**
   * DOM queries
   * @method
  **/
  function dom() {
    els.form = document.querySelector( '[data-sort-form]' );
    if( !els.form ){
      return;
    }
    els.search = els.form.querySelector( 'input[name="filter-search"]' );
    els.operator = els.form.querySelector( 'input[name="filter-tour-operator"]' );
    els.agency = els.form.querySelector( 'input[name="filter-travel-agency"]' );
    els.items = [].slice.call( document.querySelectorAll( '[data-sort-item]' ));
    els.more = document.querySelector( '[data-sort-more]' );

    if( els.more.classList.contains( 'on' )){
      full = true;
      els.more.textContent = els.more.getAttribute( 'data-more-on' );
    }

    els.items
      .filter( function( node ){
        return node.id
          && node.hasAttribute( 'data-name' )
          && node.hasAttribute( 'data-travel-agency' )
          && node.hasAttribute( 'data-tour-operator' );
      })
      .forEach( function( node ){
        data.push({
          id: node.id,
          name: node.getAttribute( 'data-name' ),
          travelAgency: node.getAttribute( 'data-travel-agency' ) === 'true',
          tourOperator: node.getAttribute( 'data-tour-operator' ) === 'true'
        });
    });
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();

    if( !els.form || (!window.filters && data.length < 1) ){
      return;
    }

    data = window.filters || data;
    visible = data;

    bind();
  }

  function reset(){
    visible = data;
    display();
  }

  function sort( e ){
    if( e.type === 'submit' ){
      e.preventDefault();
    }

    var value = els.search.value;
    if( value === els.search.getAttribute( 'placeholder' )){
      value = '';
    }


    var req;
    if( value && value !== '' ){
      req = req || {};
      req.name = stripAccents( value.toLowerCase());
    }
    if( els.agency.checked ){
      req = req || {};
      req.travelAgency = els.agency.checked;
    }
    if( els.operator.checked ){
      req = req || {};
      req.tourOperator = els.operator.checked;
    }

    if( !req ){
      reset();
      return;
    }


    var selected = [];
    data.forEach( function( item ){
      var match = true;

      if( req.name ){
        var name = stripAccents( item.name.toLowerCase());
        if( name.indexOf( req.name ) === -1 ){
          match = false;
        }
      }

      if( req.travelAgency && req.travelAgency !== item.travelAgency ){
        match = false;
      }

      if( req.tourOperator && req.tourOperator !== item.tourOperator ){
        match = false;
      }

      if( match ){
        selected.push( item );
      }

    });

    visible = selected;
    display();

  }

  function stripAccents( s ) {
    var in_chrs = 'àáâãäçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ',
      out_chrs  = 'aaaaaceeeeiiiinooooouuuuyyAAAAACEEEEIIIINOOOOOUUUUY',
      chars_rgx = new RegExp('[' + in_chrs + ']', 'g'),
      transl = {}, i,
      lookup = function ( m ) {
        return transl[m] || m;
      };

    for ( i = 0; i < in_chrs.length; i++ ) {
      transl[ in_chrs[ i ] ] = out_chrs[ i ];
    }

    return s.replace(chars_rgx, lookup);
  }

  function toggle( e ){
    e.preventDefault();
    var button = e.currentTarget;

    if( full ){
      full = false;
      button.classList.remove( 'on' );
      button.textContent = button.getAttribute( 'data-more-off' );
    }
    else {
      full = true;
      button.classList.add( 'on' );
      button.textContent = button.getAttribute( 'data-more-on' );
    }

    display();
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = Filter;
