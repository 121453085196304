/* global module, $ */

/**
 * Manages forms
 * @constructor
 */
function Forms(){
  'use strict';


  /**
   * Object to store DOM selectors
  **/
  var el,
    els = {};

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){

    // placeholders events
    var events = Modernizr.placeholder ? 'input' : 'keyup input';
    $( document ).on( events, 'form input, form textarea', placeholdersHandler );

    // Forms submit
    $( document ).on( 'submit', 'form', handleSubmit );


    // Check disabled
    $( document ).on( 'change', 'form[data-check-disable] input[required]', checkRequired );
    $( 'form[data-check-disable]' ).each( function( i, form ){
      checkRequired( form );
    });

    // Reset form parts
    $( document ).on( 'click', '[data-reset-button]', reset );

    // Block display on checked
    $( document ).on( 'change', '[data-checked-display]', checkedDisplay );
    if( els.checkboxDisplay.length ){
      els.checkboxDisplay.forEach( function( checkbox ){
        checkedDisplay( checkbox );
      });
    }

    // refresh dom
    refresh();
  }

  function callDone(){

    // re enable action buttons
    if( els.buttons ) {
      $( els.buttons ).each( function( idx, btn ){
        btn.removeAttribute( 'disabled' );
      });

      els.buttons = null;
    }
  }

  function callFail(){

  }

  function callSuccess( data ){
    var parent = $( el ).closest( '[data-form-block]' ).get(0),
      target = parent || el;

    // Publish custom event
    $.publish( 'form.success', el );

    target.innerHTML = data;
  }

  function checkedDisplay( e ){
    var input = e.currentTarget || e,
      id = input.getAttribute( 'data-checked-display' ),
      block = document.getElementById( id );

    if( !block ){
      return;
    }

    if( !!input.checked ){
      block.style.display = 'block';
    }
    else {
      block.style.display = 'none';
    }
  }


  function checkRequired( e ){
    var elt = e.target,
      $elt;

    if( elt && elt !== '' ){
      $elt = $( elt ).closest( 'form' );
    }
    else {
      $elt = $( e );
    }

    var inputs = $( 'input[type="checkbox"][required]', $elt ),
      disabled = false;
    inputs.each( function( idx, input ){
      if( !input.checked ){
        disabled = true;
      }
    });

    $elt.toggleClass( 'disabled', disabled );
  }

  /**
   * Stores all DOM elements and assign default properties
   * @method
  **/
  function dom(){
    els.popins = document.getElementById( 'popin' );
    els.checkboxDisplay = [].slice.call( document.querySelectorAll( '[data-checked-display]' ));
  }

  function handleSubmit( e ){
    el = e.target;

    if( !els.popins ){
      els.popins = document.getElementById( 'popin' );
    }

    // Do nothing if form is disabled
    if( el.classList.contains( 'disabled' )) {
      e.preventDefault();
      return;
    }

    // if form has a data-ajax attribute or is in a popin
    // submit in ajax
    if( !!el.attributes[ 'data-ajax' ] || els.popins && els.popins.contains( el )) {
      e.preventDefault();

      // Blur submit button
      var focusElt = document.activeElement;
      if( focusElt.tagName === 'BUTTON' && focusElt.type === 'submit' ) {
        focusElt.blur();
      }

      // hide the keyboard when submiting a form on touch device
      if( Modernizr.touch ){
        document.activeElement.blur();
      }

      submitForm();
    }
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();
    bind();
  }

  /**
   * Creates input placeholders for IE9
   * @method
  **/
  function placeholders( node ){
    var wrapper = node || document;

    // IE9 placeholders
    if( Modernizr.placeholder ){
      return;
    }

    var inputs = [].slice.call( wrapper.querySelectorAll( 'input[placeholder]' ));

    inputs.forEach( function( el ){
      var span = document.createElement( 'span' );
      span.className = 'placeholder';
      span.id = el.id + '-placeholder';
      span.innerHTML = el.getAttribute( 'placeholder' );

      el.setAttribute( 'aria-describedby', span.id );
      el.parentNode.insertBefore( span ,el );

      el.phEl = span;
    });

  }

  /**
   * Toggles placeholder visibility on input
   * @method
  **/
  function placeholdersHandler( e ){

    var el = e.currentTarget || e.target;

    if( el.type === 'checkbox' || el.type === 'radio' || el.type === 'hidden' || !el.id ){
      return;
    }

    var content = el.value.length,
        label = el.labelEl || el.form.querySelector([ 'label[for=', el.id, ']' ].join( '' )),
        span = el.phEl,
        phEl;

    if( !el.labelEl && label ){
      if( label.classList.contains( 'placeholder' )){
        el.labelEl = label;
      }
      else {
        label = null;
      }
    }

    phEl = label || span;

    if( !phEl ){
      return;
    }

    phEl.style.visibility = content > 0 ? 'hidden' : '';
  }

  function refresh( node ){
    var wrapper = node || document,
        inputs = [].slice.call( wrapper.querySelectorAll( 'form input, form textarea' ));

    inputs.forEach( function( input ){
      $( input ).trigger( 'input' );
    });

    // placeholders
    placeholders( wrapper );
  }

  function reset( e ){
    e.preventDefault();

    var button = e.currentTarget,
      target = $( button ).closest( '[data-reset-target]' );

    if( !target ){
      return;
    }

    // Reset inputs by types
    var inputs = [].slice.call( target[0].querySelectorAll( 'input:not([type="submit"])' ));
    inputs.forEach( function( input ){
      var type = input.getAttribute( 'type' );

      switch( type ){
        case 'radio':
        case 'checkbox':
          input.checked = false;
          input.removeAttribute( 'checked' );
          break;

        case 'number':
          var val = input.getAttribute( 'min' ),
            range = $( input ).closest( '[data-range]' ),
            slider;

          if( range ){
            slider = $( '[data-range-slider]', range );
            if( slider.length && slider[0].noUiSlider ){
              slider = slider[0].noUiSlider;
            }
            else {
              slider = null;
            }
          }

          if( input.attributes[ 'data-range-max' ]){
            val = input.getAttribute( 'max' );
            if( slider ){
              slider.set([null, +val]);
            }
          }
          else if( input.attributes[ 'data-range-min' ] ){
            slider.set([+val, null]);
          }
          input.value = val;

          break;

        case 'hidden':
          break;
          
        default:
          input.value = '';
          break;
      }
    });


  }

  function submitForm(){

    // disable action buttons
    els.buttons = el.querySelectorAll( 'button, [type=submit], [type=reset]' );
    $( els.buttons ).each( function( idx, btn ){
      btn.setAttribute( 'disabled', true );
    });

    var data = $( el ).serialize(),
      url  = el.getAttribute( 'action' );

    var params = {
      'url': url,
      'data': data,
      'type': el.getAttribute( 'method' ) || 'GET'
    };

    $.ajax( params )
      .done( callSuccess )
      .fail( callFail )
      .always( callDone );
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = Forms;
