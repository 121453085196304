/* global module, $ */
require( '../tools/jquery.rateit' );

/**
 * Handle date pickers
 * @constructor
 */
function StarRating(){
  'use strict';

  /**
   * Builds star rating
   * @method
   *
   * @param {node} el - star rating range input
  **/
  function build( el ){
    var id = el.id,
      div = document.querySelector( '[data-rateit-backingfld="#'+id+'"]' );

    if( !div ){
      div = document.createElement( 'div' );
      div.className = 'star-rating';
      $( el ).after( div );
    }

    var isBig = div.classList.contains( 'big' );

    $( div ).rateit({
      backingfld: '#' + id,
      resetable: false,
      starheight: isBig ? 23 : 13,
      starwidth: isBig ? 28 : 16
    });

  }

  /**
   * DOM queries
   * @method
   *
   * @param {node} [wrapper] - parsing container
  **/
  function dom( wrapper ) {
    wrapper = wrapper || document;
    var els = [].slice.call( wrapper.querySelectorAll( '[data-rating]' ));

    els.forEach( function( el ){
      build( el );
    });
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = StarRating;
