/* global module, $ */

/**
 * Handle Elements size for CSS translate
 * @constructor
 */
function Sizes(){
  'use strict';

  var els;

  /**
   * Stores all DOM elements and assign default properties
   * @method
  **/
  function dom(){

    els = [].slice.call( document.querySelectorAll( '[data-sizes]' ));
  }

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){

    // Media query
    $( window ).on( 'resize', resize );
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){

    dom();

    if( !els || !els.length ){
      return;
    }

    bind();
    resize();
  }

  function resize(){

    els.forEach( function( el ){
      el.classList.remove( 'init' );
      el.style.height = '';
      el.style.width = '';

      var data = el.getBoundingClientRect();
      el.style.height = data.height + 'px';
      el.style.width = Math.ceil( data.width ) + 'px';
      el.classList.add( 'init' );
    });
  }

  $.subscribe( 'document.load', init );

  return{};
}

module.exports = Sizes;
