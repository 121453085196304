/* global module, $ */

/**
 * Handle custom selects behaviour
 * @constructor
 */
function Calendar(container, params) {
  'use strict';

  if (!container) {
    return;
  }

  var currentMonth,
    currentYear,
    initMonth,
    initYear,
    highlight,
    options = {
      dates: [],
      range: [],
      l10n: {
        firstDay: 1,
        days: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
        months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
        previous: '<svg class="icon-prev" aria-hidden="true"><use xlink:href="#icon-prev"></svg><span>Précédent</span>',
        next: '<svg class="icon-prev" aria-hidden="true"><use xlink:href="#icon-prev"></svg><span>Suivant</span>'
      }

    };

  // Stores module options
  params = params || {};

  // Replaces default options with custom ones
  $.extend(true, options, params);

  function bind() {
    $(container).on('click', '.calendar-prev', prev);
    $(container).on('click', '.calendar-next', next);
  }

  function init() {

    // Set the current month, year
    var d = new Date();
    if (options.range && options.range.length) {
      var startDate = options.range[0].split('-'),
        startYear = +startDate[0],
        startMonth = +startDate[1];

      startDate = new Date(startYear, startMonth - 1);

      if (d < startDate) {
        d = startDate;
      }
    }

    initMonth = currentMonth = d.getMonth();
    initYear = currentYear = d.getFullYear();

    // Sort highlight dates
    if (options.dates && options.dates.length) {
      highlight = {};
      var regexquote = /\"/g;
      options.dates.forEach(function (selected) {
        var date;
        if (selected.date) {
          selected.date = selected.date.replace("\"", "");
          //console.log(selected.date.toString());
          date = selected.date.split('-');
        } else {
          selected = selected.replace(regexquote, "");
          //console.log(selected.toString());
          date = selected.split('-');
        }


        var year = date[0],
          month = +date[1].toString(),
          day = +date[2];


        if (!highlight[year]) {
          highlight[year] = {};
        }

        if (!highlight[year][month]) {
          highlight[year][month] = {};
        }

        var obj = {
          day: day
        };
        if (selected.text) {
          obj.text = selected.text;
        }
        if (selected.legend) {
          obj.legend = selected.legend;
        }
        //console.log("year:" + year.toString() + "month:" + month.toString() + "day:" + day.toString());
        highlight[year][month][day.toString()] = obj;
        //console.log(obj);
      });
    }

    bind();
    show();
  }

  function next() {
    if (currentMonth === 11) {
      currentMonth = 0;
      currentYear = currentYear + 1;
    } else {
      currentMonth = currentMonth + 1;
    }
    show();
  }

  function prev() {
    if (currentMonth === 0) {
      currentMonth = 11;
      currentYear = currentYear - 1;
    } else {
      currentMonth = currentMonth - 1;
    }
    show();
  }

  function show() {

    // First day of the week in the selected month
    var firstDayOfMonth = new Date(currentYear, currentMonth, 1 - options.l10n.firstDay).getDay(),
      // Last day of the selected month
      lastDateOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(),
      // Last day of the previous month
      lastDayOfLastMonth = currentMonth === 0 ? new Date(currentYear - 1, 11, 0).getDate() : new Date(currentYear, currentMonth, 0).getDate();


    // Get highlighted dates
    var dates;
    //console.log("currentYear :" + currentYear.toString() + "currentMonth :" + currentMonth.toString());
    if (highlight) {
      dates = highlight[currentYear.toString()];
      if (dates) {
        dates = dates[(currentMonth + 1).toString()];
      }
    }

    var prevClassname = '',
      nextClassname = '';

    // Get prev/next buttons display
    if (options.range && options.range.length) {
      var min = options.range[0],
        max = options.range[1];

      if (min) {
        min = min.split('-');
        if (min.length > 1) {
          var minYear = +min[0],
            minMonth = +min[1];

          if (currentYear <= minYear && (currentMonth + 1) <= minMonth) {
            prevClassname = ' hidden';
          }
        } else if (min.length && min[0] === 'current') {
          if (currentYear <= initYear && currentMonth <= initMonth) {
            prevClassname = ' hidden';
          }
        }
      }

      if (max) {
        max = max.split('-');
        if (max.length > 1) {
          var maxYear = +max[0],
            maxMonth = +max[1];

          if (currentYear >= maxYear && (currentMonth + 1) >= maxMonth) {
            nextClassname = ' hidden';
          }
        } else if (max.length && max[0] === 'current') {
          if (currentYear >= initYear && currentMonth >= initMonth) {
            nextClassname = ' hidden';
          }
        }
      }
    }

    var currentMonthPlusOne = currentMonth + 1;

    // Build calendar header
    var html = [
      '<div class="calendar-title" ',
      'data-month="',
      currentYear,
      "-",
      (currentMonthPlusOne < 10) ? "0" + currentMonthPlusOne : currentMonthPlusOne,
      '">',
      '<div>',
      options.l10n.months[currentMonth], ' ', currentYear,
      '</div>',
      '<button class="calendar-prev', prevClassname, '" type="button">',
      options.l10n.previous,
      '</button>',
      '<button class="calendar-next', nextClassname, '" type="button">',
      options.l10n.next,
      '</button>',
      '</div>'
    ];

    // Write selected month and year


    // Write the header of the days of the week
    html.push(
      '<table class="calendar-table updated">',
      '<thead>',
      '<tr>'
    );
    options.l10n.days.forEach(function(day) {
      html.push('<th scope="col">', day, '</th>');
    });
    html.push(
      '</tr>',
      '</thead>',
      '<tbody>'
    );

    // Write the days
    var i = 1,
      lines = 0,
      k;
    do {
      var currentDate = new Date(currentYear, currentMonth, i);
      var dow = currentDate.getDay();

      // If Sunday, start new row
      if (dow === options.l10n.firstDay) {
        html.push('<tr>');
      }
      // If not Sunday but first day of the month
      // it will write the last days from the previous month
      else if (i === 1) {
        html.push('<tr>');

        k = lastDayOfLastMonth - firstDayOfMonth + 1;
        for (var j = 0; j < firstDayOfMonth; ++j) {
          html.push('<td class="not-current"><span>', k, '</span></td>');
          ++k;
        }
      }

      // Write the current day in the loop
      var classname = '',
        spanClassname = '',
        ct = ['<span>', i.toString(), '</span>'].join('');

      if (dates) {
        //console.log("has dates");
        var cell = dates[i.toString()];
        //console.log("i is : " + i.toString());
        //console.log("dates: " + dates[i.toString()]);
        // if dans ma date on me spécifie une classe


        // mes spans avec ma class

        // else
        if (typeof cell !== 'undefined') {
          console.log('defined');
          if (cell.legend) {
            spanClassname = [' class="', cell.legend, '" '].join('');
            //console.log('has legend');
          } else {
            classname = ' class="selected" ';
            //console.log('selected');
          }


          if (cell.text || cell.legend) {
            ct = [ '<span', spanClassname, '>', i, '</span>', '<p>', cell.text, '</p>'].join('');
            //console.log('has text or legend');
          }
        }
      }
      console.log('render cell');
      html.push('<td data-date="', currentDate, '"', classname, '>', ct, '</td>');

      var end = options.l10n.firstDay === 0 ? 6 : options.l10n.firstDay - 1;
      k = 1;

      // If last day of week, closes the row
      if (dow === end) {
        html.push('</tr>');
        ++lines;
      }

      // If not Saturday, but last day of the selected month
      // it will write the next few days from the next month
      else if (i === lastDateOfMonth) {
        for (dow; dow < 6 + options.l10n.firstDay; ++dow) {
          html.push('<td class="not-current"><span>', k, '</span></td>');
          k++;
        }
        html.push('</tr>');
        ++lines;
      }

      if (lines < 6 && i === lastDateOfMonth) {
        for (lines; lines < 6; ++lines) {
          dow = 0;

          html.push('<tr>');

          for (dow; dow < 6 + options.l10n.firstDay; ++dow) {
            html.push('<td class="not-current"><span>', k, '</span></td>');
            k++;
          }

          html.push('</tr>');
        }
      }

      ++i;
    }
    while (i <= lastDateOfMonth);

    // Closes table
    html.push(
      '</tbody>',
      '</table>'
    );

    container.innerHTML = html.join('');
  }

  init();

  return {};
}

module.exports = Calendar;
