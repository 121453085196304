/* global module, $ */
var moment = require( '../tools/moment' ),
Pikaday = require( '../tools/pikaday' );

/**
 * Handle date pickers
 * @param  {object} site - Main object
 * @constructor
 */
function Datepicker( site ){
  'use strict';

  var els,
    options = {
      firstDay: 0,
      showDaysInNextAndPreviousMonths: true,
      format: 'ddd D MMM GGGG',
      minDate: moment().toDate()
    };

  if( site.l10n.datepicker ){
    moment.locale( site.l10n.datepicker.locale || 'fr' );
    $.extend( true, options, site.l10n.datepicker );
  }

  /**
   * DOM queries
   * @method
  **/
  function dom() {
    els = [].slice.call( document.querySelectorAll( '[data-datepicker]' ));

    els.forEach( function( el ){
      var value = el.getAttribute( 'data-datepicker' ),
        selected;

      if( value && value !== '' ){
        var date = moment( value );
        if ( date.isValid()){
          selected = date;
        }
      }

      var opts = {
        field: el,
        onSelect: function( selectedDate ){
          manage( el, selectedDate );

          // Manage duplicated fields
          sameFields( el, selectedDate );
        },
        onClose: function(){
          if( !el.value || el.value === '' ){
            manage( el );

            // Manage duplicated fields
            sameFields( el );
          }
        }
      };

      var min = el.getAttribute( 'data-datepicker-min' );
      if( min && min === 'today' ){
        var now = moment().toDate();
        opts.minDate = now;

        if( selected && selected < now ){
          selected = now;
        }
      }

      if( selected ){
        opts.defaultDate = selected;
        opts.setDefaultDate = true;
      }

      // Test min/max associated field
      var start = el.getAttribute( 'data-datepicker-start' ),
        end = el.getAttribute( 'data-datepicker-end' ),
        input, val;

      if( start ){
        input = document.querySelector( '[data-datepicker-end="'+start+'"]' );
        if( input ){
          val = input.getAttribute( 'data-datepicker' );
          if( val && val !== '' ){
            opts.maxDate = moment( val ).toDate();
          }
        }
      }
      else if( end ){
        input = document.querySelector( '[data-datepicker-start="'+end+'"]' );
        if( input ){
          val = input.getAttribute( 'data-datepicker' );
          if( val && val !== '' ){
            var minDate = moment( val ).toDate();
            if( opts.minDate && minDate < opts.minDate ){
              minDate = opts.minDate;
            }
            opts.minDate = minDate;
          }
        }
      }

      if( opts.minDate && opts.maxDate && opts.minDate > opts.maxDate ){
        opts.maxDate = opts.minDate;
      }

      var params = $.extend( true, {}, options, opts );

      if( el.getAttribute( 'data-datepicker-format' )){
        params.format =  el.getAttribute( 'data-datepicker-format' );
      }

      el.pikaday = new Pikaday( params );
    });
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();
  }

  /**
   * Manages associated date fields
   * @param  {node} el - date field
   * @param  {date} [date] - Selected date
   * @method
  **/
  function manage( el, date ){
    var attributes = el.attributes,
      start = attributes[ 'data-datepicker-start' ],
      end =  attributes[ 'data-datepicker-end' ],
      id, input;

    if( start ){
      id = start.value;
      input = document.querySelector( '[data-datepicker-end="'+id+'"]' );
      if( input && input.pikaday ){
        if( date ){
          input.pikaday.setMinDate( date );
          input.pikaday.gotoDate( date );
        }
        else {
          input.pikaday.setMinDate( options.minDate );
        }
      }
    }
    else if( end ){
      id = end.value;
      input = document.querySelector( '[data-datepicker-start="'+id+'"]' );
      if( input && input.pikaday ){
        if( date ){
          input.pikaday.setMaxDate( date );
        }
        else {
          input.pikaday.setMaxDate( options.maxDate || moment( options.minDate ).add( 10, 'y' ).toDate());
        }
      }
    }
  }

  /**
   * Manages duplicated fields
   * @param  {node} el - date field
   * @param  {date} [date] - Selected date
   * @method
  **/
  function sameFields( el, date ){
    var attributes = el.attributes,
      same = attributes[ 'data-datepicker-same' ];

    if( same ){
      var sameEls = [].slice.call( document.querySelectorAll( '[data-datepicker-same="' + same.value + '"]' ));
      if( !date ){
        sameEls.forEach( function( elt ){
          if( elt !== el ){
            elt.value = el.value;
            manage( elt );
          }
        });
      }
      else {
        sameEls.forEach( function( elt ){
          if( elt !== el ){
            if( !moment( elt.pikaday.getDate()).isSame( moment( el.pikaday.getDate()))){
              elt.pikaday.setDate( date );
            }
          }
        });
      }
    }
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = Datepicker;
