/* global module, $ */

/**
 * Handle Video module
 * @constructor
 */
function Video( site ){
  'use strict';

  var els,
    mobile,
    isReady,
    mq = window.matchMedia( '(min-width: 40rem)' );

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){

    // Media query
    mq.addListener( resize );
    resize();
  }

  /**
   * DOM queries
   * @method
  **/
  function dom() {
    els = [].slice.call( document.querySelectorAll( '[data-video]' ));
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();
    if( !els || !els.length ){
      return;
    }

    // Init youtube api
    setupEmbed();

    bind();
  }

  /**
   * Injects a script
   * @method
   * @param {string} source - api url
  **/
  // Inject a script
  function injectScript( source ){

    if( document.querySelectorAll( 'script[src="' + source + '"]' ).length ){
      return;
    }

    var tag = document.createElement( 'script' ),
      firstScriptTag = document.getElementsByTagName('script')[0];

    tag.src = source;
    firstScriptTag.parentNode.insertBefore( tag, firstScriptTag );
  }

  function resize(){
    mobile = !mq.matches;

    els.forEach( function( el ){
      var iframe,
        parent;

      if( mobile ){
        iframe = el.querySelector( 'iframe' );
        if( iframe ){
          parent = iframe.parentNode;
          parent.removeChild( iframe );

          parent.classList.remove( 'playing' );
        }

      }
      else {
        var src = el.getAttribute( 'data-video' );
        src = src.replace( /&amp;/g, '&' );

        if( src.indexOf( 'enablejsapi' ) === -1 ){

          if( src.indexOf( '?' ) === -1 ){
            src += '?enablejsapi=1';
          }
          else {
            src += '&enablejsapi=1';
          }
        }

        iframe = [ '<iframe src="', src ,'" frameborder="0" allowfullscreen></iframe>' ].join( '' );

        el.insertAdjacentHTML( 'beforeend', iframe );
        setPlayer();
      }

    });
  }

  function setPlayer(){
    if( !isReady ){
      return;
    }
    els.forEach( function( el ){
      var iframe = el.querySelector( 'iframe' );

      if( iframe ){
        var player = new YT.Player( iframe, {
          events: {
            'onStateChange': function( state ){
              var node = state.target.getIframe().parentNode;
              if( state.data === 1 ||  state.data === 3 ){
                node.classList.add( 'playing' );
              }
            }
          }
        });
      }

    });
  }

  /**
   * Calls youtube api
   * @method
  **/
  function setupEmbed(){

    // Load the API
    injectScript( site.conf.youtube.api );

    // Setup callback for the API
    window.onYouTubeReadyCallbacks = window.onYouTubeReadyCallbacks || [];

    // Add to queue
    window.onYouTubeReadyCallbacks.push(
      youTubeReady
    );

    // Set callback to process queue
    window.onYouTubeIframeAPIReady = function (){
      window.onYouTubeReadyCallbacks.forEach( function( callback ){
        callback();
      });
    };
  }

  function youTubeReady(){
    isReady = true;
    setPlayer();
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = Video;
