/* global module, $ */

/**
 * Manages AnchorNav
 * @constructor
 */
function AnchorNav() {
  'use strict';

  var wrapper,
    els = {},
    mobile,
    data = {
      mq: window.matchMedia('(min-width: 62.5rem)'),
      pos: null,
      delta: 0,
      direction: 'down',
      navTop: 0
    };

  /**
   * Set up all event listeners
   * @method
  **/
  function bind() {

    // Media query
    data.mq.addListener(resize);

    // Refresh event
    $.subscribe('scrollRefresh', refresh);
  }



  /**
   * Stores all DOM elements and assign default properties
   * @method
  **/
  function dom() {
    els.nav = document.querySelector('.anchor-nav');
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init() {

    //wrapper = document.querySelector( 'header[role="banner"]' );
    wrapper = document.body;
    if (!wrapper) {
      return;
    }

    dom();
    bind();
    resize();
  }

  /**
   * Unbinds events
   * @method
  **/
  function off() {
    $(wrapper).off('click.anchor-nav');
    $(window).off('scroll.anchor-nav');
  }

  /**
   * Binds events
   * @method
  **/
  function on() {
    off();
    if (!mobile && !Modernizr.touchevents) {

    }
    else {

    }

    if (!mobile) {
      $(window).on('scroll.anchor-nav', scrolling);
    }
  }

  /**
   * Refresh DOM elements
   * @method
  **/
  function refresh() {
    if (!mobile) {
      // Get anchor nav position
      var pos = document.documentElement.scrollTop - 80 || document.body.scrollTop - 80;
      if (els.nav != null)
      {
        // data.navTop = els.nav.getBoundingClientRect().top + pos;
        data.navTop = 462;
      }
    }
  }



  /**
   * Manages mediaqueries changes
   * @method
  **/
  function resize() {
    mobile = !data.mq.matches;

    // Reinit events
    on();

    //
    refresh();

    //
    if (!mobile) {
      scrolling(true);
    }
    else {
      if (els.nav != null) {
        if (els.nav.classList.contains('sticky')) {
          els.nav.classList.remove('sticky');
        }
      }

    }


    if (mobile) {
      $(document).off('click.anchor-nav');
    }

  }


  function scrolling(resize) {
    var pos = document.documentElement.scrollTop || document.body.scrollTop,
      direction = 'down';

    if (data.pos === pos && !resize) {
      return;
    }

    if (data.pos > pos) {
      direction = 'up';
    }

    if (direction === data.direction) {
      data.delta += Math.abs(data.pos - pos);
    }
    else {
      data.delta = 0;
    }

    // Store position
    data.direction = direction;
    data.pos = pos;

    sticky();
  }

  function sticky() {
    if (mobile) {
      if (els.nav != null) {
        els.nav.classList.remove('sticky');
        return;
      }
    }

    if (data.pos > data.navTop) {
      if (els.nav != null) {
        if (!els.nav.classList.contains('sticky')) {
          var headerPosition = document.getElementById('menuDesktop').getBoundingClientRect().bottom;
          els.nav.classList.add('sticky');
          document.getElementsByClassName('anchor-nav sticky')[0].setAttribute('style', 'top:' + headerPosition + 'px');
        }
      }
    }
    else{
      if(document.getElementsByClassName('anchor-nav sticky').length > 0){
        document.getElementsByClassName('anchor-nav sticky')[0].removeAttribute('style');
        els.nav.classList.remove('sticky');
      }
    }
  }

  $.subscribe('app.start', init);

  return {};
}

module.exports = AnchorNav;
