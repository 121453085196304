
/**
 * Manages AnchorNav
 * @constructor
 */
 function NavFooter(){
    'use strict';
    var wrapper,
    els = {},
    mobile,
    data = {
      mq: window.matchMedia( '(min-width: 62.5rem)' ),
      pos: null,
      delta: 0,
      direction: 'down',
      navTop: 0
    };
    
  function hide(){
    //Lors du chargement de la page
    $("#section-middle .links").hide();
  }

  
  function movedivs(){
    $( "#section-middle .links" ).each(function() {
      $(this).appendTo($(this).prev());
    });
  }

  function actions(){
//Pour cacher et mettre en gris le menu 'acces rapide' et 'site b2b'

$("#section-middle .tt-raw-light span").click(function () {
  $( "#section-middle" ).animate({
    margin: "20px 0 100px 0"  }, 100 );
  $("#section-middle .tt-raw-light span").css({ opacity: 0.5 });
  $("#section-middle .tt-raw-light .contact-link span").css({ opacity: 1 });
  $("#section-middle .links").hide();
  $(this).css({ opacity: 1 });
  ($(this).next(".links")).toggle();
  ($(this).next(".links")).css({ opacity: 1 });
});
}
function init(){
  wrapper = document.body;
    if( !wrapper ) {
      return;
    }
    mobile = !data.mq.matches;
    if(mobile){hide();
    actions();
    movedivs();
    }
  }
$.subscribe( 'app.start', init );

return{};
 }

 
module.exports = NavFooter;