/**
 * Manages AnchorNav
 * @constructor
 */
function SearchEngine() {
  'use strict';
  var wrapper,
    els = {},
    mobile,
    data = {
      mq: window.matchMedia('(min-width: 62.5rem)'),
      pos: null,
      delta: 0,
      direction: 'down',
      navTop: 0
    };

  function init() {
    wrapper = document.body;
    if (!wrapper) {
      return;
    }
    mobile = !data.mq.matches;
    if (mobile) {

    }

    // On attend que le contenu du DOM soit chargé.
    document.addEventListener('DOMContentLoaded', function () {
      const loadMores = document.querySelectorAll('.loadmore');
      if(loadMores == null)
        return;

      loadMores.forEach(function(loadmore){
        loadmore.addEventListener('click', function () {
          const parent = this.parentElement;
          var childrens = parent.children;

          for(var i = 0; i < childrens.length; i++){
            const item = childrens[i];
            var contains = item.classList[0] == "search-item" && item.classList[1] == "hidden";
            if(contains)
            item.classList.remove('hidden');
          }

          this.style.display = "none";

        });
      });
      
      /*
        
      // Au clic sur le bouton Load More
      loadMore.addEventListener('click', function () {
        // Récupération de la liste des élements masqués
        const hidden = Array.prototype.slice.call(document.querySelectorAll('.search-item.hidden'));

        // Variables d'affichages
        var defaultNumberToRemove = 5;
        var hiddenCount = hidden.length;
        var hiddenLeft = hiddenCount;
        var currentIndex = 0;
        var indexMaxToRemove = 0;
        var finalNumberToRemove = 0;

        // Calculs avant application d'affichage
        if (hiddenLeft == 0)
          return;
        else if (hiddenLeft >= defaultNumberToRemove) {
          finalNumberToRemove = defaultNumberToRemove;
          indexMaxToRemove += defaultNumberToRemove;
        }
        else {
          finalNumberToRemove = hiddenLeft;
          indexMaxToRemove += hiddenLeft;
        }

        //Permet de prendre des éléments d'un index à un autre
        hidden.slice(currentIndex, indexMaxToRemove).forEach(function (elem) {
          elem.classList.remove('hidden'); // on affiche les élements
        });

        //Mise à jour des index min/max
        currentIndex += finalNumberToRemove;
        hiddenLeft -= finalNumberToRemove;

        // Si il n'existe plus d'élements masqués
        if (hiddenLeft == 0)
          loadMore.style.display = "none"; // on masque le bouton LoadMore.

      });

      */

    });

  }

  $.subscribe('app.start', init);

  return {};
}

module.exports = SearchEngine;