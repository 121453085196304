/* global module, $ */

/**
 * Handle tabs behaviour
 * @constructor
 */
function Tabs(){
  'use strict';

  var els;

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){
    els.forEach( function( btn ) {
      $( btn ).on( 'click', toggle );
    });

    $( document ).on( 'click', '[data-tab-close]', close );
  }

  /**
   * Close button handler
   * @method
   *
   * @param  {event} e - Click event
  **/
  function close( e ){
    e.preventDefault();
    var button = e.currentTarget,
      id = button.getAttribute( 'data-tab-close' ),
      toggler = document.querySelector( '[aria-controls="'+id+'"]' );

    if( toggler && toggler.getAttribute( 'aria-expanded' ) === 'true' ){
      $( toggler ).trigger( 'click' );
    }
  }

  /**
   * DOM queries
   * @method
  **/
  function dom(){
    els = document.querySelectorAll( '[data-tab]' );
    els = [].slice.call( els );
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();
    bind();
  }

  /**
   * Toggle associated block
   * @method
  **/
  function toggle( e ) {
    e.preventDefault();
    var button   = !!e.currentTarget ? e.currentTarget : e,
        isOpened = button.getAttribute( 'aria-expanded' ).toLowerCase() === 'true',
        target   = document.getElementById( button.getAttribute( 'aria-controls' ) );

    if ( !target ) {
      return;
    }

    button.setAttribute( 'aria-expanded', String( !isOpened ) );

    if( isOpened ){
      target.style.display = 'block';
      $( target ).stop().slideUp({
        duration: 300,
        start: function(){
          target.classList.toggle( 'on', !isOpened );
        }
      });
    }
    else {
      var sliders = [].slice.call( target.querySelectorAll( '.slick-slider' ));
      if( sliders.length ){
        target.style.display = 'block';
        sliders.forEach( function( slider ){
          if( slider.slick ){
            slider.slick.refresh();
          }
        });
        target.style.display = '';
      }

      window.setTimeout( function() {
        $( target ).stop().slideDown({
          duration: 300,
          start: function(){
            target.classList.toggle( 'on', !isOpened );

            if( sliders.length ){
              window.setTimeout( function() {
                sliders.forEach( function( slider ){
                  if( slider.slick ){
                    slider.slick.refresh();
                  }
                });
              }, 100 );
            }
          }
        });
      }, 1 );
    }

    // target.classList.toggle( 'on', !isOpened );
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = Tabs;
