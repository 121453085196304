/* global $ */

// Helpers
require( '../../../Common/scripts/helpers/classList' );
require( '../../../Common/scripts/helpers/matchMedia' );
require( '../../../Common/scripts/helpers/matchMedia.addListener' );

// Import Vendors
window.jQuery = require( 'jquery' );
window.$ = window.jQuery;

require( '../../../Common/scripts/tools/jquery.tiny-pubsub' );
require( '../../../Common/scripts/tools/velocity' );
require( '../../../Common/scripts/modules/overflowScroll' );

// var fc = require( '../../../Common/scripts/vendor/fastclick' );

  // Import modules
var AutoComplete = require( '../../../Common/scripts/modules/autoComplete' ),
  BookingSearch = require( './modules/bookingSearch' ),
  BookingBar = require( './modules/bookingBar' ),
  CalendarSlider = require( '../../../Common/scripts/modules/calendarSlider' ),
  Cookies = require( '../../../Common/scripts/modules/cookies' ),
  Datepicker = require( '../../../Common/scripts/modules/datepicker' ),
  Directory = require( '../../../Common/scripts/modules/directory' ),
  //Dropdown = require( '../../../Common/scripts/modules/dropdown' ),
  Events = require( '../../../Common/scripts/modules/events' ),
  FileImg = require( '../../../Common/scripts/modules/file' ),
  Filter = require( '../../../Common/scripts/modules/filter' ),
  Forms = require( '../../../Common/scripts/modules/forms' ),
  Maps = require( '../../../Common/scripts/modules/maps' ),
  Nav = require( '../../../Common/scripts/modules/nav' ),
  OfferRecap = require( './modules/offerRecap' ),
  OfferSearch = require( './modules/offerSearch' ),
  PlaceMap = require( './modules/placeMap' ),
  Popins = require( '../../../Common/scripts/modules/popins' ),
  Listener = require( '../../../Common/scripts/modules/listener' ),
  Load = require( '../../../Common/scripts/modules/load' ),
  LoadMore = require( '../../../Common/scripts/modules/loadmore' ),
  RangeSlider = require( '../../../Common/scripts/modules/range' ),
  Search = require( './modules/search' ),
  ScrollBar = require( './modules/scrollBar' ),
  //Dropdown = require( './modules/dropdownDestinations' ),
  Selects = require( '../../../Common/scripts/modules/selects' ),
  Sizes = require( '../../../Common/scripts/modules/sizes' ),
  Sliders = require( '../../../Common/scripts/modules/sliders' ),
  Sorting = require( '../../../Common/scripts/modules/sorting' ),
  StarRating = require( '../../../Common/scripts/modules/starRating' ),
  Tabs = require( '../../../Common/scripts/modules/tabs' ),
  Toggle = require( '../../../Common/scripts/modules/toggle' ),
  ToTop = require( '../../../Common/scripts/modules/toTop' ),
  Video = require( '../../../Common/scripts/modules/video' ),
  SideFilter = require('./modules/sideFilter'),
  //ContactForm = require('./modules/contactForm'),
  SearchBoxMap = require('./modules/searchBoxMap'),
  DropDownMobile = require('./modules/dropdownMobile'),
  ZoomSlider = require( './modules/zoomSlider' ),
  AnchorNav = require( './modules/anchorNav' ),
  NavFooter = require( './modules/navFooter' ),
  SearchEngine = require( './modules/searchEngine' ),
  MagnificPopup = require( './modules/magnific-popup' ),
  Discount = require('./modules/discount');


var Site = function(){

  // fastclick
  // if( Modernizr.touchevents ){
  //   fc( document.body );
  // }

  // Config
  if( window.config ){
    this.conf = $.extend( {}, window.config.conf );
    this.l10n = $.extend( {},
      document.documentElement.lang.substring(0,2) && window.config.l10n[document.documentElement.lang.substring(0,2)]
      ? window.config.l10n[document.documentElement.lang.substring(0,2)]
      : window.config.l10n );
  }

  this.autoComplete = new AutoComplete( this );
  this.bookingSearch = new BookingSearch( this );
  this.calendarSlider = new CalendarSlider( this );
  this.cookies = new Cookies( this );
  this.datepicker = new Datepicker( this );
  this.bookingBar = new BookingBar( this );
  this.directory = new Directory( this );
  //this.dropdown = new Dropdown( this );
  this.events = new Events( this );
  this.filter = new Filter( this );
  this.fileImg = new FileImg( this );
  this.forms = new Forms( this );
  this.listener = new Listener( this );
  this.loadMore = new LoadMore( this );
  this.maps = new Maps( this );
  this.nav = new Nav( this );
  this.offerRecap = new OfferRecap( this );
  this.offerSearch = new OfferSearch( this );
  this.placeMap = new PlaceMap( this );
  this.popins = new Popins( this );
  this.load = new Load( this );
  this.rangeSlider = new RangeSlider( this );
  this.search = new Search( this );
  this.selects = new Selects( this );
  this.sizes = new Sizes( this );
  this.sliders = new Sliders( this );
  this.sorting = new Sorting( this );
  this.starRating = new StarRating( this );
  this.tabs = new Tabs( this );
  this.toggle = new Toggle( this );
  this.toTop = new ToTop( this );
  this.video = new Video( this );
  this.zoomSlider = new ZoomSlider( this );
  this.anchorNav = new AnchorNav( this );
  this.navFooter = new NavFooter( this );
  this.searchEngine = new SearchEngine( this );
  this.discount = new Discount( this );


  // Launch app
  $.publish( 'app.start' );

  $( document ).on( 'DOMContentLoaded', function(){
    $.publish( 'document.load' );
  });

  $( window ).on( 'load', function(){
    $.publish( 'window.load' );
  });
};

new Site();
