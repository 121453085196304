// Custom scrollbar of the dropdown Destinations

var container = document.getElementById('scrollbar-container'),
    content = document.getElementById('content'),
  scrollBar = document.getElementById('scrollbar');

if (content && scrollBar && container) {

  content.addEventListener('scroll', function(e) {
    scrollBar.style.height = "calc(" + container.clientHeight * content.clientHeight / content.scrollHeight + "px - 0.4rem)";
    scrollBar.style.top = container.clientHeight * content.scrollTop / content.scrollHeight + "px";
  });

  // var event = new Event('scroll');
  // Event Constructor not supported on IE
  // stackoverflow.com/questions/26596123/internet-explorer-9-10-11-event-constructor-doesnt-work/26596324
  var event = null;
  try {
      event = new Event("scroll");
  }
  catch (error) {
      event = document.createEvent("Event");
      var doesnt_bubble = false;
      var isnt_cancelable = false;
      event.initEvent("scroll", doesnt_bubble, isnt_cancelable);
  }

  window.addEventListener('resize', content.dispatchEvent.bind(content, event));
  content.dispatchEvent(event);

  scrollBar.addEventListener('mousedown', function(start) {
    start.preventDefault();
    var y = scrollBar.offsetTop;

    function onMove(end) {
      var delta = end.pageY - start.pageY;
      scrollBar.style.top = Math.min(container.clientHeight - scrollBar.clientHeight, Math.max(0, y + delta)) + 'px';
      content.scrollTop = (content.scrollHeight * scrollBar.offsetTop / container.clientHeight);
    };

    document.addEventListener('mousemove', onMove);

    document.addEventListener('mouseup', function(){
      document.removeEventListener('mousemove', onMove);
    });
  });

};
