/* global module, $ */

/**
 * Manages Discount
 * @constructor
 */
function Discount() {
  'use strict';
  
  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init() {
    document.addEventListener('DOMContentLoaded', function () {
      $(".toast").toast('show');
    });
  }

  $.subscribe('app.start', init);

  return {};
}

module.exports = Discount;
