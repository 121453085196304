/* global module, $ */

/**
 * Handle Sorting behavior
 * @constructor
 */
function Sorting(){
  'use strict';

  var els = {},
    datas = [],
    initial;

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){
    $( document ).on( 'click', '[data-sorting]', sort );
  }

  /**
   * DOM queries
   * @method
  **/
  function dom(){
    els.items = [].slice.call( document.querySelectorAll( '[data-sorting-item]' ));

    if( !els.items.length ){
      return;
    }

    els.items.forEach( function( item ){
      var obj = JSON.parse( item.getAttribute( 'data-sorting-item' ));
      obj.id = item.id || item.parentNode.id;
      datas.push( obj );
    });

    initial = datas.slice();
  }

  function filter( type ){

    datas = initial.slice();

    datas.sort( function( a, b ){
      var x, y;
      switch( type ){
        case 'title':
          x = a.title.toLowerCase();
          y = b.title.toLowerCase();

          return x < y ? -1 : x > y ? 1 : 0;

        case 'date':
          x = new Date( a.date );
          y = new Date( b.date );
          return x - y;

        case 'grade':
        case 'selection':
          x = +a[type];
          y = +b[type];
          return y - x;

        default:
          x = +a[type];
          y = +b[type];
          return x - y;
      }
    });

    datas.forEach( function( entry ){
      var node = document.getElementById( entry.id );
	  if (node != null && node.parentNode != null)
	  {
		node.parentNode.appendChild( node );
	  }
    });
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();

    if( !els.items.length ){
      return;
    }

    bind();
  }

  function sort( e ){
    e.preventDefault();

    var button = e.currentTarget,
      type = button.getAttribute( 'data-sorting' );

    if( button.classList.contains( 'on' )){
      return;
    }

    // document.querySelector( '[data-sorting="' + type + '"]' ).classList.add( 'on' );

    filter( type );

    document.querySelector( '[data-sorting].on' ).classList.remove( 'on' );
    button.classList.add( 'on' );

  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = Sorting;
