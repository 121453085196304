/* global module, $, google */

/**
 * Handle contacts behaviour
 * @constructor
 */
function Maps( site ){
  'use strict';

  var els = [];


  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){
    $( window ).on( 'resize', resize );
  }

  /**
   * DOM queries
   * @method
  **/
  function dom( wrapper ){
    wrapper = wrapper || document;

    var nodes = [].slice.call( wrapper.querySelectorAll( '[data-gmap]' ));

    if( !nodes.length ){
      return;
    }

    els = els.concat( nodes );

    function callback(){
      nodes.forEach( function( el ){
        var gps = el.getAttribute( 'data-gmap' ).split( ',' );
        if( gps.length ){
          create( el, gps );
        }
      });
    }

    if( typeof google === 'undefined' || typeof google.maps === 'undefined' ){
      load();
      $.subscribe( 'gmap.load', function(){
        $.unsubscribe( 'gmap.load' );
        callback();
      });
    }
    else {
      callback();
    }
  }

  /**
   * Creates Google map
   * @method
   *
   * @param  {node} el - DOM map element
   * @param  {array} gps - Marker/center Lat and Lng
  **/
  function create( el, gps ){

    var options = {
        zoom: 13,
        panControl: false,
        draggable: false,
        mapTypeControl: false,
        overviewMapControl: false,
        rotateControl: false,
        scrollwheel: false,
        streetViewControl: false,
        styles: [
        {
            featureType: "poi",
            stylers: [
              { visibility: "off" }
            ]   
          }
        ],
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
        }
      },
      latLng = {
        lat: +gps[0],
        lng: +gps[1]
      },
      image = {
        url: site.conf && site.conf.gmap && site.conf.gmap.marker ? site.conf.gmap.marker : '/img/marker.png',
        size: new google.maps.Size( 66, 88 ),
        origin: new google.maps.Point( 0, 0 ),
        anchor: new google.maps.Point( 16, 44 ),
        scaledSize: new google.maps.Size( 33, 44 )
      };

    $.extend( options, {
      center: latLng
    });

    var map = new google.maps.Map( el, options );
    new google.maps.Marker({
      position: latLng,
      icon: image,
      map: map
    });
    el.gmap = map;

  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();

    if( !els.length ){
      return;
    }
    bind();
  }

  /**
   * Loads Google maps API
   * @method
  **/
  function load(){
    window.gmapLoad = function() {
      $.publish( 'gmap' );
    };

    var script = document.createElement( 'script' );
    script.src = 'https://maps.google.com/maps/api/js?key='+ ( site.conf.gmap.key || '' ) +'&callback=gmapLoad';

    document.head.appendChild( script );
  }

  /**
   * Centers maps on window resize
   * @method
  **/
  function resize(){
    els.forEach( function( el ){
      var map = el.gmap,
        gps = el.getAttribute( 'data-gmap' ).split( ',' ),
        latLng = {
          lat: +gps[0],
          lng: +gps[1]
        };

      map.setCenter( latLng );
    });
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = Maps;
