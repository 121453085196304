/* global module, $ */


/**
 * Manages Nav
 * @constructor
 */
function Nav() {
  'use strict';

  var wrapper,
    els = {},
    mobile,
    data = {
      mq: window.matchMedia('(min-width: 62.5rem)'),
      pos: null,
      delta: 0,
      direction: 'down',
      navTop: 0
    };

  /**
   * Set up all event listeners
   * @method
  **/
  function bind() {

    // Media query
    data.mq.addListener(resize);

    // Refresh event
    $.subscribe('scrollRefresh', refresh);
  }


  /**
   * Close layer on document click
   * @method
  **/
  function documentClose(e) {
    if (e.target.id === 'nav' || els.nav[0].contains(e.target)) {
      return;
    }

    var expanded = [].slice.call(wrapper.querySelectorAll('[aria-expanded="true"]'));
    expanded.forEach(function (el) {
      toggle(el, null);
    });
  }

  /**
   * Stores all DOM elements and assign default properties
   * @method
  **/
  function dom() {
    els.header = document.querySelector('header[role="banner"]');
    els.expand = [];

    if (els.header) {
      els.expand = [].slice.call(els.header.querySelectorAll('[aria-expanded]'));
    }
    //els.nav = document.getElementById('nav');
    els.nav = document.getElementsByClassName('menu');
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init() {

    //wrapper = document.querySelector( 'header[role="banner"]' );
    wrapper = document.body;
    if (!wrapper) {
      return;
    }

    dom();
    bind();
    resize();
    resizeSubnav();
    mobileCustom();
    bindLangRedirect();
    loadHeaderSearchBar();
  }

  /**
   * Unbinds events
   * @method
  **/
  function off() {
    $(wrapper).off('mouseenter.nav mouseleave.nav click.nav');
    $(window).off('scroll.nav');
  }

  /**
   * Binds events
   * @method
  **/
  function on() {
    off();
    if (!mobile && !Modernizr.touchevents) {
      $(wrapper).on('mouseenter.nav mouseleave.nav', 'header[role="banner"] .categories-nav > li', toggle);
    }
    else {
      $(wrapper).on('click.nav', 'header[role="banner"] [aria-expanded]', toggle);
    }

    if (!mobile) {
      $(window).on('scroll.nav', scrolling);
    }
  }

  /**
   * Refresh DOM elements
   * @method
  **/
  function refresh() {

    // Set initial display
    els.expand.forEach(function (el) {
      toggle(el, true);
    });

    if (!mobile) {
      // Get nav position
      var pos = document.documentElement.scrollTop || document.body.scrollTop;
      data.navTop = els.nav[0].getBoundingClientRect().top + pos;
    }

  }

  /**
   * Manages mediaqueries changes
   * @method
  **/
  function resize() {

    mobile = !data.mq.matches;

    // Reinit events
    on();

    //
    //refresh();

    //
    if (!mobile) {
      scrolling(true);
    }
    /*else {
      if( els.nav.classList.contains( 'sticky' )){
        els.nav.classList.remove( 'sticky' );
      }
    }

    var expanded = [].slice.call( els.nav.querySelectorAll( '[aria-expanded="true"]' ));

    if( mobile ){
      $( document ).off( 'click.nav' );
    }
    else if( expanded.length ){
      $( document ).on( 'click.nav', documentClose );

      if( !Modernizr.touchevents ){
        expanded.forEach( function( el ){
          toggle( el );
        });
      }
    }*/
  }

  /**
   * Toggles nav layers
   * @method
  **/
  function toggle(e, init) {
    var button,
      target;

    // Test if parameter is an event or a DOM element
    if (e.hasOwnProperty('originalEvent')) {
      // e.preventDefault();
      button = e.currentTarget;
    }
    else {
      button = e;
    }

    if (mobile && button.attributes['data-desktop-expand']) {
      target = document.getElementById(button.getAttribute('aria-controls'));
      target.classList.remove('open');
      button.setAttribute('aria-expanded', 'false');
      return;
    }

    // Test if parameter is an event or a DOM element
    if (e.hasOwnProperty('originalEvent')) {
      e.preventDefault();
    }

    var attr = button.getAttribute('aria-expanded');
    if (!attr) {
      button = button.querySelector('[aria-expanded]');

      if (!button) {
        return;
      }
      attr = button.getAttribute('aria-expanded');
    }
    
    var expanded = !!attr && attr === 'true';
    target = document.getElementById(button.getAttribute('aria-controls'));
    
    // If init don't change state
    if (init) {
      expanded = !expanded;
    }

    // Toggle associated layer display
    if (expanded) {
      target.classList.remove('open');
      button.setAttribute('aria-expanded', 'false');

      $(document).off('click.nav');
    }
    else {
      target.classList.add('open');
      button.setAttribute('aria-expanded', 'true');

      // if (els.nav.contains(button) && (Modernizr.touchevents || mobile)) {
      //   var opened = [].slice.call(els.nav.querySelectorAll('[aria-expanded="true"]'));
      //   opened.forEach(function (el) {
      //     if (button !== el) {
      //       toggle(el);
      //     }
      //   });

      //   if (!mobile) {
      //     $(document).on('click.nav', documentClose);
      //   }
      // }
    }

  }

  function scrolling(resize) {
    var pos = document.documentElement.scrollTop || document.body.scrollTop,
      direction = 'down';

    if (data.pos === pos && !resize) {
      return;
    }

    if (data.pos > pos) {
      direction = 'up';
    }

    if (direction === data.direction) {
      data.delta += Math.abs(data.pos - pos);
    }
    else {
      data.delta = 0;
    }

    // Store position
    data.direction = direction;
    data.pos = pos;

    // sticky();
  }

/*   function sticky() {
    if (mobile) {
      els.nav[0].classList.remove('sticky');
      return;
    }

    if (data.pos > data.navTop) {
      if (!els.nav[0].classList.contains('sticky')) {
        els.nav[0].classList.add('sticky');
      }
    }
    else {
      if (els.nav[0].classList.contains('sticky')) {
        els.nav[0].classList.remove('sticky');
      }
    }
  } */

  function resizeSubnav() {

    mobile = !data.mq.matches;

    if (!mobile) {
      $(".category-details").each(function () {
        var width = $(this).width();
        width = width + 60;
        $(this).width(width);
      })
    }
  }

  function mobileCustom() {


    mobile = !data.mq.matches;

    //if (mobile) {
      $(".cross_nav, .middle-menu, .bottom-menu, .category-details-mobile, .sub-nav, .choix-langue, .up, .croix").hide();

      //Menu Burger pour cacher et afficher menu navigation
      $(".bt-nav").click(function () {
        if ($(".hide-menu").height() > 0) {
          $(".hide-menu").height("0");
          $(".hide-menu").css("position", "");
        }
        else {
          $(".hide-menu").height("100%");
          $(".hide-menu").css("position", "sticky");
        }
        $(".middle-menu, .bottom-menu").toggle();
        $(this).find('img').toggle();

      });

      //Affichage du menu premier niveau
      $('.span-menu').click(function () {
        $(this).next().toggle();
        $(this).find(".bi").toggleClass("bi-chevron-down").toggleClass("bi-chevron-up");
        $(".category-details-mobile").not($(this).next()).slideUp();

      });


      //Affichage du menu second niveau
      $('.second-menu').click(function () {
        $(this).next().toggle();
        $(this).css({ color: '#69b5c2' });
        $(this).find(".bi").toggleClass("bi-arrow-right").toggleClass("bi-x");

        $(".second-menu:not(:active)").css({ color: '#182883' });

      });

      //Affichage du choix de la langue
      $(".list-langue").click(function () {
        $(".choix-langue").toggle();
        $(this).find(".bi").toggleClass("bi-arrow-right").toggleClass("bi-x");
      });



    //}
  }

  function bindLangRedirect() {
    if (mobile) {
      // Bind event
      $(".header-mobile .choix-langue").on('change', function (e) {
        var elt = e.target,
          optIndex = elt.selectedIndex,
          url = elt.value;

        if (url && url !== '') {
          window.location.href = url;
        }
      });
    }
  }
  
  
  function loadHeaderSearchBar() {
    if (!mobile) {
      var searchBar = $("#searchbar-desk");
      if (searchBar != null) {
        searchBar.hide();
      }
    }
    // Afficher champ de recherche et mettre les icons en opacity
    $(".searchIcon").click(function () {
      $("#searchbar-desk").toggle();
      $(".static-icon li").toggleClass('withopacity');
      $(".static-icon .searchIcon").css("opacity", "1");
      $(".static-icon-basket li").toggleClass('withopacity');
    });

    // Desactiver le bouton SUBMIT par defaut
    $('#bta-submit').prop('disabled', true)
    $('#submit-mob').prop('disabled', true)

    // Mettre la fleche du bouton submit en bleu DESKTOP
    $('#searchDesktop').keyup(function() {
      if( $(this).val() != "" ) {
        $(".bi-arrow-right").css("color","#69b5c2");
        $('#bta-submit').prop('disabled', false)
      }
      else {
        $(".bi-arrow-right").css("color","inherit")
        $('#bta-submit').prop('disabled', true)
      }
    });
    // Mettre la fleche du bouton submit en bleu MOBILE
    $('#searchMobile').keyup(function () {
      if ($(this).val() != "") {
        $(".bi-arrow-right").css("color", "#69b5c2");
        $('#submit-mob').prop('disabled', false)
      }
      else {
        $(".bi-arrow-right").css("color", "inherit")
        $('#submit-mob').prop('disabled', true)
      }
    });

  }

  function getCurrentMarket(){

    const ContextMarket = { undetermined: 0,
                            DeDE: 1,
                            EnCA: 2,
                            EnFR: 3,
                            EnGB: 4,
                            EnUS: 5,
                            FrCA: 6,
                            FrFR: 7 };

    var currentContext;                        

    const url = window.location.href;

    const findTerm = function(term) {
        const contains = url.toLowerCase().includes(term.toLowerCase());
        return contains;
    };

    if(findTerm('/fr-fr'))
     currentContext = ContextMarket.FrFR;
    else if(findTerm('/en-gb'))
      currentContext = ContextMarket.EnGB;
    else if(findTerm('/en-fr'))
      currentContext = ContextMarket.EnFR;
    else if(findTerm('/en-us'))
      currentContext = ContextMarket.EnUS;
    else if(findTerm('/en-ca'))
      currentContext = ContextMarket.EnCA;
    else if(findTerm('/fr-ca'))
      currentContext = ContextMarket.FrCA;

    return currentContext;


  }
  
  function updateSuggestionsElements(list, isForMobile){
    if(list == undefined || list == null)
    return;
    const suggestionInput = document.getElementById(isForMobile ? 'suggestion-search-mobile': 'searchbox-suggestion-desk');

    var listData = "";

      for(var i = 0; i < list.length ; i++)
      {

        var item = '<a class="link-item" href="'+ list[i].url+'"><div class="suggestion-item">'+'<span class="title">'+ list[i].title + '</span><span class="link"> '+list[i].url+'</span>'+'</div></a>';
        listData += item;
        if(isForMobile && i >= 3)
        {
          i = list.length;
        }

      }

    suggestionInput.innerHTML = listData;
    if(listData.length > 0 ){
      displaySuggestion(true, isForMobile);
      
    }
    
    if(listData == "")
    {
        displaySuggestion(false, isForMobile);
        //not display 
        
      }else{
        displaySuggestion(true, isForMobile);
      }
  }

  function updateSuggestion(q, isForMobile){
    if(q != ''){
      var context = getCurrentMarket();
      var url = "/api/b2c/search/suggestion";
      const json_data = {
          query: q,
          origin : context
      };
    
      $.ajax({
        url: url,
        type: "POST",
        async: true,
        data: json_data,
        success: function (response) {
          updateSuggestionsElements(response, isForMobile);
        }});
    }
    else{
      displaySuggestion(false, isForMobile);
    }

  }

  function displaySuggestion(show ,isForMobile){
    var suggestionBox = document.getElementById(isForMobile ? 'suggestion-search-mobile' : 'searchbox-suggestion-desk');
    if(show)
    {
      suggestionBox.classList.remove("hide");
      suggestionBox.classList.add("show");
    }else if(!show){
      suggestionBox.classList.add("hide");
      suggestionBox.classList.remove("show");
    }
    
  }

  const searchInputDesktop = document.getElementById('searchDesktop');

  if(searchInputDesktop){
    searchInputDesktop.addEventListener('keyup', function(e) {
      e.preventDefault();
      updateSuggestion(e.target.value, false); 
    });

    searchInputDesktop.onblur = function(e){
      e.preventDefault();
      var isForMobile = false;
      var showSuggestion = false;

      if(e.relatedTarget == null){
        displaySuggestion(showSuggestion, isForMobile)
      }
      else if(e.relatedTarget.className != "link-item"){
        displaySuggestion(showSuggestion, isForMobile);
      }
      else{ // do nothing 
      }
      
    }
    
  }

  const searchInputMobile = document.getElementById('searchMobile');

  if(searchInputMobile){
    searchInputMobile.addEventListener('keyup', function(e) {
      e.preventDefault();
      updateSuggestion(e.target.value, true);
    });

    searchInputMobile.onblur = function(e){
      e.preventDefault();
      var isForMobile = true;
      var showSuggestion = false;

      if(e.relatedTarget == null){
        displaySuggestion(showSuggestion, isForMobile)
      }
      else if(e.relatedTarget.className != "link-item"){
        displaySuggestion(showSuggestion, isForMobile);
      }
      else{ // do nothing 
      }
      
    }

  }


  $.subscribe('app.start', init);

  return {};
}

module.exports = Nav;
