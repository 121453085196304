/* global module, $, google */
require( '../tools/baron' );

/**
 * Handle Directory Map
 * @constructor
 */
function Directory( site ){
  'use strict';

  var el,
    els = {},
    content,
    params = {},
    mobile,
    map,
    markers,
    images = {},
    mq = window.matchMedia( '(min-width: 62.5rem)' );


  function append( data ){
    var $div = $( [
      '<div class="view-cat">',
          '<div class="inner">',
            '<button type="button" class="cat-button" data-directory-toggle>',
              '<span class="arrow">',
                '<svg aria-hidden="true" class="icon-prev-light">',
                  '<use xlink:href="#icon-prev-light"></use>',
                '</svg>',
              '</span>',
              '<picture><!--[if IE 9]><video style="display: none;"><![endif]-->',
                '<source srcset="' + data.picto.Src + '"><!--[if IE 9]></video><![endif]--><img alt="" src="' + data.picto.Src + '">',
              '</picture><span>' + data.title + '</span>',
            '</button>',
            '<div class="scroll">',
              '<div class="scroller-wrapper">',
                '<div class="scroller-content">',
                  '<ul class="address-list">',
                  '</ul>',
                '</div>',
              '</div>',
            '</div>',
          '</div>',
        '</div>',
      '</div>'
    ].join( '' ));

    // get items
    data.places.forEach( function( place, i ){
      var item = [
        '<li>',
          '<button data-index="', i ,'" type="button" aria-expanded="false" aria-controls="address-', i, '" class="address-button">',
            '<span>', place.name , '</span>',
          '</button>',
          '<div id="address-', i, '" class="address-details">'
      ];
      if( place.text ){
        item.push( '<p>', place.text , '</p>' );
      }
        item.push( '<ul>' );
        if( place.address ){
          item.push(
            '<li>',
              '<svg aria-hidden="true" class="icon-place">',
                '<use xlink:href="#icon-place"></use>',
              '</svg>',
              '<p>', place.address, '</p>',
            '</li>'
          );
        }
        if( place.hours ){
          item.push(
            '<li>',
              '<svg aria-hidden="true" class="icon-place">',
                '<use xlink:href="#icon-clock-2"></use>',
              '</svg>',
              '<p>', place.hours, '</p>',
            '</li>'
          );
        }
        if( place.phone ){
          item.push(
            '<li>',
              '<svg aria-hidden="true" class="icon-place">',
                '<use xlink:href="#icon-phone-2"></use>',
              '</svg>',
              '<p>', place.phone, '</p>',
            '</li>'
          );
        }
        item.push( '</ul>' );
      item.push(
          '</div>',
        '</li>'
      );

      $( '.address-list', $div ).append( item.join( '' ));
    });


    var style = [
      '<style>',
        '.directory-section .view-cat .address-button[aria-expanded="true"],',
        '.directory-section .view-cat .address-button:hover,',
        '.directory-section .view-cat .address-button.rollover {',
          'background: ', data.theme, ';',
          'color: ', data.color,
        '}',
        '.directory-section .view-cat .address-details [class*="icon-"] {',
          'fill: ', data.theme,
          '}',
      '<style>'
    ].join( '' );

    $div
      .append( style )
      .velocity(
        {
          translateX: '-100%'
        },
        {
          duration: 0
        });

    $( els.details ).append( $div );
    window.picturefill({
      elements: $( 'img', $div )
    });


    window.setTimeout( function(){
      $div[0].style.display = 'block';
      if( !mobile ){
         $( '.scroll', $div ).each( function( i, scroll ){
          var $scroll = $( scroll ),
            $scrollbar = $( '<div class="scroller-track _v"><div class="scroller-bar _v"></div></div>' );

          $scroll.append( $scrollbar );
          $scroll.baron({
            scroller: '.scroller-content',
            barOnCls: 'scroller',
            bar: '.scroller-bar',
            track: '.scroller-track',
            scrollingCls: 'scrolling',
            draggingCls: 'dragging'
          });

        });
      }
      slideRight( $div );
    }, 100 );

  }

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){

    $( el ).on( 'click', '.close', closeIntro );

    $( el ).on( 'click', '[data-directory-scroll]', goTo );

    // Media query
    // mq.addListener( resize );
    $( window ).on( 'resize', resize );
  }

  function center(){
    if(map){
      var options = {};
      $.extend( options, params, mobile ? content.map.mobile : content.map.desktop );

      map.setCenter( options.center );
      map.setZoom( options.zoom );

      if( !mobile ){
        map.panBy( -100, 0 );
      }
    }
  }

  function clearMarkers(){
    markers.forEach( function( marker){
      marker.setMap( null );
    });
  }

  function closeIntro( e ){
    e.preventDefault();
    var button = e.currentTarget,
      intro = $( button ).closest( '.list-intro' );

    intro.slideUp();
  }

  function create(){
    var options = {};
    params = {
      center: {
        lat: 16.206651,
        lng: -61.570734
      },
      zoom: 10,
      draggable: true,
      // panControl: false,
      mapTypeControl: false,
      overviewMapControl: false,
      rotateControl: false,
      scrollwheel: false,
      streetViewControl: false,
      styles: [
        {
          featureType: "poi",
          stylers: [
            { visibility: "off" }
          ]
        }
      ],
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM
      }
    };

    $.extend( options, params, mobile ? content.map.mobile : content.map.desktop );

    map = new google.maps.Map( els.map, options );
    if( !mobile ){
      map.panBy( -100, 0 );
    }

  }

  /**
   * DOM queries
   * @method
  **/
  function dom() {

    els.mapContainer = el.querySelector( '.map-container' );
    els.map = el.querySelector( '[data-directory-map]' );
    els.details = el.querySelector( '.list-details' );
    els.content = el.querySelector( '.content' );
    els.info = el.querySelector( '.map-info' );
  }

  function getPixelPosition( marker ) {
    var scale = Math.pow( 2, map.getZoom()),
      nw = new google.maps.LatLng(
        map.getBounds().getNorthEast().lat(),
        map.getBounds().getSouthWest().lng()
      ),
      proj = map.getProjection(),
      worldCoordinateNW = proj.fromLatLngToPoint( nw ),
      worldCoordinate = proj.fromLatLngToPoint( marker.getPosition()),
      pixelOffset = new google.maps.Point(
        Math.floor(( worldCoordinate.x - worldCoordinateNW.x ) * scale ),
        Math.floor(( worldCoordinate.y - worldCoordinateNW.y ) * scale )
      );

    return pixelOffset;
  }


  function goTo( e ){
    e.preventDefault();

    var target     = document.querySelector( e.currentTarget.hash ),
        posY       = target ? target.getBoundingClientRect().top : 0,
        currentPos = window.pageYOffset,
        newPos     = currentPos <= 0 ? posY : currentPos + posY;

    $( 'html, body' ).animate({
      scrollTop: newPos
    }, 650 );
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    el = document.querySelector( '[data-directory]' );
    if( !el ){
      return;
    }

    store();

    dom();
    bind();
    resize();
  }

  /**
   * Loads Google maps API
   * @method
  **/
  function load(){
    window.gmapLoad = function() {
      $.publish( 'gmap' );
    };

    var script = document.createElement( 'script' );
    script.src = 'https://maps.google.com/maps/api/js?key='+ ( site.conf.gmap.key || '' ) +'&callback=gmapLoad';

    document.head.appendChild( script );
  }

  function on(){
    $( el ).on( 'click', '[data-directory-button]', view );
    $( el ).on( 'click', '[aria-expanded]', toggle );
    if( !Modernizr.touchevents ){
      $( el ).on( 'mouseenter mouseleave', '[aria-expanded]', rollover );
    }

    $( el ).on( 'click', '[data-directory-toggle]', slideLeft );
  }

  function resize(){
    var state = !mq.matches;
    // mobile = !mq.matches;

    center();

    if( state === mobile ) {
      return;
    }

    mobile = state;

    var home = els.details.querySelector( '.view-home' ),
      layer = els.details.querySelector( '.view-cat' );

    if( !mobile ){

      home.style.display = 'block';

      if( layer ){
        $( layer ).css({
          position: 'absolute',
          minHeight: ''
        });
      }

      $( '.scroll', el ).each( function( i, scroll ){
        var $scroll = $( scroll ),
          $scrollbar = $( '<div class="scroller-track _v"><div class="scroller-bar _v"></div></div>' );

        $scroll.append( $scrollbar );
        $scroll.baron({
          scroller: '.scroller-content',
          barOnCls: 'scroller',
          bar: '.scroller-bar',
          track: '.scroller-track',
          scrollingCls: 'scrolling',
          draggingCls: 'dragging'
        });

      });
    }
    else {
      $( '.scroller', el ).each( function( i, scroll ) {
        var $scroll = $( scroll );

        $scroll.baron().dispose();
        $( '.scroller-track', $scroll ).remove();
        $( '.scroller-wrapper, .scroller-content', $scroll ).removeAttr( 'style' );
      });

      if( layer ){
        $( layer ).css({
          position: 'relative'
        });
        home.style.display = 'none';
      }
    }
  }

  function rollover( e ){
    var button = e.currentTarget,
      idx = +button.getAttribute( 'data-index' ),
      isOpened = button.getAttribute( 'aria-expanded' ).toLowerCase() === 'true';

    if( e.type === 'mouseenter' ) {
      if( !isOpened ){
        markers[ idx ].setIcon( images.rollover );
      }
      button.classList.add( 'rollover' );
    }
    else {
      if( !isOpened ){
        markers[ idx ].setIcon( images.off );
      }
      button.classList.remove( 'rollover' );
    }
  }

  function slideLeft( e ){
    e.preventDefault();

    // Remove markers
    clearMarkers();

    var home = els.details.querySelector( '.view-home' ),
      layer = els.details.querySelector( '.view-cat' );

    home.style.display = 'block';

    var homeHeight = home.getBoundingClientRect().height,
      divHeight = layer.getBoundingClientRect().height;

    els.details.style.height = divHeight + 'px';

    $( layer )
      .css({
        left: 0,
        position: 'absolute',
        top: 0
      });

    if( homeHeight !== divHeight ){
      $( els.details ).velocity({
        height: homeHeight
      },
      {
        complete: function(){
          els.details.style.height = '';
        }
      });
    }
    else {
      els.details.style.height = '';
    }

    if( mobile ){
      home.style.opacity = 0;
      $( home ).velocity({
        opacity: 1
      },
      {
        delay: 200,
        duration: 200
      });
    }
    else {
      home.style.opacity = 1;
    }

    $( layer )
      .velocity(
        {
          translateX: '-100%'
        },
        {
          complete: function(){
            $( layer ).remove();
          }
        }
      );

    els.content.classList.remove( 'visible' );

  }

  function slideRight( $div ){

    var home = els.details.querySelector( '.view-home' ),
      homeHeight = home.getBoundingClientRect().height,
      divHeight = $div[0].getBoundingClientRect().height;

    if( homeHeight !== divHeight ){
      $( els.details ).velocity({
        height: divHeight
      });
    }

    if( mobile ){
      $( home ).velocity({
        opacity: 0
      },
      {
        duration: 200
      });
    }

    $div.velocity(
      {
        translateX: 0
      },
      {
        complete: function(){
          home.style.display = 'none';
          $div.css({
            position: 'relative'
          });
          els.details.style.height = '';
        }
      }
    );

    els.content.classList.add( 'visible' );


    center();
  }

  function store(){
    var url  = site.conf.directory.datas;
    var locale = window.locale;

    $.ajax({
      'url': url + '?locale=' + locale
    })
      .done( function( data ){
        content = data;
        on();

        function callback(){
          create();
        }

        if( typeof google === 'undefined' || typeof google.maps === 'undefined' ){
          load();
          $.subscribe( 'gmap.load', function(){
            $.unsubscribe( 'gmap.load' );
            callback();
          });
        }
        else {
          callback();
        }
      })
      .fail( function(){
      })
      .always( function(){
        // console.log( arguments );
      });
  }

  function toggle( e ){

    var button   = !!e.currentTarget ? e.currentTarget : e,
        isOpened = button.getAttribute( 'aria-expanded' ).toLowerCase() === 'true',
        target   = document.getElementById( button.getAttribute( 'aria-controls' ) );

    if ( !target ) {
      return;
    }

    $( button.parentNode ).siblings().each( function( idx, item ){
      var bt = item.querySelector( '[aria-expanded="true"]' );
      if( bt ){
        toggle( bt );
      }
    });

    var idx = +button.getAttribute( 'data-index' );

    button.setAttribute( 'aria-expanded', String( !isOpened ) );
    if( isOpened ){
      target.style.display = 'block';
      $( target ).stop().slideUp( 300, function(){
        if( !mobile ){
          $( target ).closest( '.scroll' ).baron().update();
        }
      });
      if( button.classList.contains( 'rollover' )){
        markers[ idx ].setIcon( images.rollover );
      }
      else {
        markers[ idx ].setIcon( images.off );
      }
      button.blur();
    }
    else {
      $( target ).stop().slideDown( 300, function(){
        if( !mobile ){
          $( target ).closest( '.scroll' ).baron().update();
        }
      });
      markers[ idx ].setIcon( images.on );
      var markerPos = markers[ idx ].getPosition();
      if( !map.getBounds().contains( markerPos )){
        center();
      }
      else {
        if( !mobile ){
          var markerPixelsPos = getPixelPosition( markers[ idx ] ),
            sideBound = els.info.getBoundingClientRect(),
            min = sideBound.left,
            max = min + sideBound.width;

          if( markerPixelsPos.x > min - 20 && markerPixelsPos.x < max + 20 ){
            center();
          }
        }
      }

    }

    target.classList.toggle( 'on', !isOpened );
  }

  function view( e ){
    e.preventDefault();
    var button = e.currentTarget,
      key = button.getAttribute( 'data-directory-button' ),
      data = $.grep(content, function (c)
      {
          return c.name.replace(/ /g, '_').toLowerCase() === key;
      });

    if (data) {
      append( data[0]);

      data = data[0];
      images.on = {
        url: data.markerOn.Src,
        size: new google.maps.Size( data.markerOn.Width, data.markerOn.Height ),
        origin: new google.maps.Point( 0,0 ),
        anchor: new google.maps.Point( data.markerOn.Width / 4, data.markerOn.Height / 2 ),
        scaledSize: new google.maps.Size( data.markerOn.Width / 2, data.markerOn.Height / 2 )
      };
      images.rollover = {
        url: data.markerOver.Src,
        size: new google.maps.Size( data.markerOver.Width, data.markerOver.Height),
        origin: new google.maps.Point( 0,0 ),
        anchor: new google.maps.Point( data.markerOver.Width / 4, data.markerOver.Height / 4),
        scaledSize: new google.maps.Size( data.markerOver.Width / 2, data.markerOver.Height / 2 )
      };
      images.off = {
        url: data.markerOff.Src,
        size: new google.maps.Size( data.markerOff.Width, data.markerOff.Height ),
        origin: new google.maps.Point( 0,0 ),
        anchor: new google.maps.Point( data.markerOff.Width / 4, data.markerOff.Height / 4 ),
        scaledSize: new google.maps.Size( data.markerOff.Width / 2, data.markerOff.Height / 2 )
      };

      if( map ){
        markers = [];
        data.places.forEach( function( place, idx ){
          var marker = new google.maps.Marker({
            position: { lat: place.lat, lng: place.lng},
            icon: images.off,
            map: map
          });
          marker.idx = idx;

          marker.addListener( 'click', function() {
            $( '.view-cat .address-list > li:nth-child('+ ( this.idx + 1 ) + ') .address-button', el ).trigger( 'click' );
          });

          if( !Modernizr.touchevents ){
            marker.addListener( 'mouseover', function() {
              $( '.view-cat .address-list > li:nth-child('+ ( this.idx + 1 ) + ') .address-button', el ).trigger( 'mouseenter' );
            });

            marker.addListener( 'mouseout', function() {
              $( '.view-cat .address-list > li:nth-child('+ ( this.idx + 1 ) + ') .address-button', el ).trigger( 'mouseleave' );
            });
          }

          markers.push( marker );
        });
      }
    }
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = Directory;
