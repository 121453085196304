/* global module, $ */

/**
 * Handle Zoom sliders
 * @constructor
 */
function zoomSlider( site ){
  'use strict';

  var els = {},
    isMobile,
    mq = window.matchMedia( '(min-width: 40rem)' );

  var tpl = [
    '<div class="zoom-opacity"></div>',
    '<div class="zoom">',
      '<button data-zoom-slider-close type="button" class="close">',
        '<svg aria-hidden="true" class="icon-close">',
          '<use xlink:href="#icon-close"></use>',
        '</svg>',
      '</button>',
      '<div class="content">',
        '<div class="slider">',
        '</div>',
      '</div>',
    '</div>'
  ],
  sliderOptions = {
    infinite: true,
    dots: false,
    prevArrow: [
      '<button type="button" class="slick-prev">',
        '<svg class="icon-prev-light" aria-hidden="true"><use xlink:href="#icon-prev-light"></svg>',
        '<span>', site.l10n.slider.previous, '<span>',
      '</button>'
    ].join( '' ),
    nextArrow: [
      '<button type="button" class="slick-next">',
        '<svg class="icon-prev-light" aria-hidden="true"><use xlink:href="#icon-prev-light"></svg>',
        '<span>', site.l10n.slider.next, '<span>',
      '</button>'
    ].join( '' )
  };

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){

    // Media query
    mq.addListener( resize );
    resize();

    // Close button
    $( document ).on( 'click', '[data-zoom-slider-close]', close );
  }

  function close( e ){
    e.preventDefault();
    var button = e.currentTarget,
      $target = $( button ).closest( '[data-zoom-slider-target]' );

    $( '.zoom, .zoom-opacity', $target )
      .animate({
        opacity: 0
      })
      .promise()
      .done( function(){

        $( '.slick-slider', $target ).slick( 'unslick' );
        $( '.zoom, .zoom-opacity', $target ).remove();

      });

  }

  /**
   * DOM queries
   * @method
  **/
  function dom() {
    els.targets = [].slice.call( document.querySelectorAll( '[data-zoom-slider-target]' ));

    if( !els.targets || !els.targets.length ){
      return;
    }

    els.sliders = [].slice.call( document.querySelectorAll( '[data-zoom-slider]' ));
    els.buttons = [].slice.call( document.querySelectorAll( '[data-zoom-button]' ));
  }

  function desktop(){

    // bind zoom events
    $( els.buttons ).on( 'click', zoom );

    // Remove sliders
    slidersRemove();
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();

    if( !els.targets || !els.targets.length ){
      return;
    }

    bind();
  }

  function mobile(){

    // unbind zoom events
    $( els.buttons ).off( 'click', zoom );

    // Create sliders
    slidersCreate();
  }

  function resize(){
    isMobile = !mq.matches;

    if( isMobile ){
      mobile();
    }
    else {
      desktop();
    }
  }

  function slidersCreate(){

    els.sliders.forEach( function( slider ){
      $( slider ).slick( sliderOptions );
    });
  }

  function slidersRemove(){
    els.sliders.forEach( function( slider ){
      if( slider.slick ){
        $( slider ).slick( 'unslick' );
      }
    });
  }

  function zoom( e ){
    e.preventDefault();
    var button = e.currentTarget,
      $list = $( button ).closest( '[data-zoom-slider]' ).find( '[data-zoom-button]' ),
      $target = $( button ).closest( '[data-zoom-slider-target]' );

    if( $( '.zoom', $target ).length ){
      return;
    }

    var index = $list.index( button );

    $target[0].insertAdjacentHTML( 'afterbegin', tpl.join( '' ));

    $list.each( function( idx, item ){
      var data = JSON.parse( item.getAttribute( 'data-zoom-button' )),
        altimg = $(item).prev('picture').find('img').attr('alt')||'',
        node = [
          '<div class="slide">',
            '<picture>',
              '<!--[if IE 9]><video style="display: none;"><![endif]-->',
              '<source srcset="', data.img ,', ', data.img2x ,' 2x">',
              '<!--[if IE 9]></video><![endif]-->',
              '<img alt="',altimg,'" src="', data.img ,'">',
            '</picture>',
            '<p>',
              data.desc,
            '</p>',
          '</div>'
        ];

        $( '.slider', $target )[0].insertAdjacentHTML( 'beforeend', node.join( '' ));
    });

    window.picturefill({
      elements: $( 'img', $target )
    });

    // init slider
    $( '.zoom .slider', $target ).slick( $.extend( {}, sliderOptions, {initialSlide: index }));
    
    $(".zoom img").on('load', function(){
      var dechirureHeight = document.querySelector('.dechirure-offer-div').offsetHeight;

      var bgDivBase = document.querySelector('.offer-description.grey-background').offsetHeight;
      var zoomHeight = document.querySelector('.zoom').offsetHeight;
      var minZoomHeight = zoomHeight + 150;

      if (bgDivBase > minZoomHeight){
          $('.zoom').css({
            "top" : (dechirureHeight + ((bgDivBase - zoomHeight) / 2)) + "px",
            "left" : "25%"
          });
          $('.zoom-opacity').css({
            "top" : dechirureHeight + "px",
            "height" : bgDivBase + "px",
            "min-height" : minZoomHeight + "px"
          });
      }
      else{
          $('.zoom').css({
            "top" : (dechirureHeight + 75) + "px",
            "left" : "25%"
          });
          $('.zoom-opacity').css({
            "top" : dechirureHeight + "px",
            "height" : minZoomHeight + "px",
            "min-height" : minZoomHeight + "px"
          });
      }

      
    });

    $( '.zoom, .zoom-opacity', $target ).animate({
      opacity: 1
    },
    {
      duration: 300
    });

  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = zoomSlider;
