document.addEventListener("DOMContentLoaded", function (event) {

  var des_acc = document.getElementById('Destination_acc'),
    des_act = document.getElementById('Destination_act'),
    des_rest = document.getElementById('Destination_rest'),
    des = document.getElementById('Destination'),
    innerwidth = window.innerWidth;

  /*Check initial width and change dropdown position accordingly*/
  dropdownPos(innerwidth);

  /* Check Mobile Device or not */
  var isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function () {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
  };


  /* Remove keyboard only for Mobile Device*/
  if (isMobile.any()) {

    if (des_acc && des_acc != null) { des_acc.setAttribute('readonly', 'true'); }
    if (des_act && des_act != null) { des_act.setAttribute('readonly', 'true'); }
    if (des_rest && des_rest != null) { des_rest.setAttribute('readonly', 'true'); }
    if (des && des != null) { des.setAttribute('readonly', 'true'); }
    
  }

  /*Change dropdown position on resizing*/
  $(window).on('resize', function () {
    var innerwidth = window.innerWidth;
    dropdownPos(innerwidth);

  });


  /* Adjust Dropdown position according to window innerWidth*/
  function dropdownPos(innerwidth) {
    if (innerwidth < 1000) {

      $('#dropdown_accommodation').css('top', "94px");
    
      $('#dropdown_activity').css('top', '141.2px');

      $('#dropdown_restaurant').css('top', '282px');

      if (innerWidth < 768) {
        $('#dropdown_activity').css('left', '2%');
        $('#dropdown_accommodation').css('left', "2%");
        $('#dropdown_restaurant').css('left', '2%');
      }

    } else {
      $('#dropdown_accommodation').css('top', "");
      $('#dropdown_activity').css('top', '');
      $('#dropdown_restaurant').css('top', '');
      $('#dropdown_accommodation').css('left', "");
      $('#dropdown_activity').css('left', '');
      $('#dropdown_restaurant').css('left', '');
    }


  }


});
