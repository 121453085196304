/* global module, $ */
require( '../tools/jquery.autocomplete' );

/**
 * Manages autocomplete feature
 * @module autocomplete
**/

function AutoComplete( site ){

  var els;

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){

    $.subscribe( 'input.refresh', refresh );
  }

  /**
   * DOM queries
   * @method
  **/
  function dom() {
    els = [].slice.call( document.querySelectorAll( '[data-autocomplete]' ));

    els.forEach( function( el ){
      var type = el.getAttribute( 'data-autocomplete' ),
        api = site.conf.autoComplete[ type ];

      if( typeof api !== 'undefined' ){
        var options = {
          serviceUrl: api,
          paramName: 'search'
        };
        $( el ).autocomplete( options );

        el.classList.add( 'autocomplete-init' );

        if( el.readOnly ){
          $( el ).autocomplete().disable();
        }
      }
    });
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){

    dom();

    bind();
  }

  function refresh( e, input ){
    $( input ).autocomplete().disable();

    if( !input.readOnly ){
      $( input ).autocomplete().enable();
    }
  }

  $.subscribe( 'app.start', init );

  return {};
}

module.exports = AutoComplete;
