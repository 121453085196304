/* global module, $ */

/**
 * Generic module to handle scroll and resize event in a healthy and performant way (throttling etc)
 * @constructor
 */
function Listener(){
  'use strict';

  /**
   * Object to store datas
   * @property {object} data - Default data
   * @property {number} data.delta - Scroll amount delta
   * @property {string} data.direction - Scroll direction `[up|down]
   * @property {number} data.pos - Scroll position in pixels
  **/
  var data = {
    delta: 0,
    direction: 'down',
    pos: null
  };

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){

    // Scroll event
    $( document ).on( 'scroll', scroll );

    // Resize event
    $( window ).on( 'resize', debounce( resize, 150 ));
  }


  /**
   * Returns a function, that, as long as it continues to be invoked, will not
   * be triggered. The function will be called after it stops being called for
   * N milliseconds. If `immediate` is passed, trigger the function on the
   * leading edge, instead of the trailing.
   * @method
   */
  function debounce( func, wait, immediate ){
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if( !immediate ){
          func.apply( context, args );
        }
      };
      var callNow = immediate && !timeout;
      clearTimeout( timeout );
      timeout = setTimeout( later, wait );
      if( callNow ){
        func.apply( context, args );
      }
    };
  }

  /**
   * DOM queries
   * @method
  **/
  function dom(){

    // Store inital scroll position
    data.pos = document.documentElement.scrollTop || document.body.scrollTop;
  }

  /**
   * Stores and returns scroll position.
   * @method
   * @return {object} Scroll current position
  **/
  function getPos(){

    set();

    return {
      direction: data.direction,
      pos: data.pos
    };
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();
    bind();
  }

  /**
   * Handles resize event, stores dimensions and triggers custom event.
   * @method
  **/
  function resize(){
    var width = window.innerWidth,
      height = window.innerHeight,
      params = {
        height: false,
        width: false
      };

    if( width !== data.width ) {
      params.width = width;
    }

    if( height !== data.height ) {
      params.height = height;
    }

    // Trigger custom event
    $.publish( 'resize', params );

    // Store new dimensions
    data.height = height;
    data.width = width;
  }

  /**
   * Handles scroll event, stores position and triggers custom event.
   * @method
  **/
  function scroll(){
    // if( site.data.noScroll ){
    //   return;
    // }

    set();

    // Trigger custom event
    $.publish( 'scroll', {
      direction: data.direction,
      pos: data.pos,
      delta: data.delta
    });
  }

  /**
   * Stores scroll position.
   * @method
  **/
  function set(){
    var pos = document.documentElement.scrollTop || document.body.scrollTop,
      direction = 'down';

    if ( data.pos === pos ) {
      return;
    }

    if ( data.pos > pos ) {
      direction = 'up';
    }

    if ( direction === data.direction ) {
      data.delta += Math.abs( data.pos - pos );
    }
    else {
      data.delta = 0;
    }

    // Store position
    data.direction = direction;
    data.pos = pos;
  }

  $.subscribe( 'app.start', init );

  return{
    getPos: getPos
  };
}

module.exports = Listener;
