/* global module, $ */

/**
 * Handle cookies banner
 * @constructor
 */
function Cookies(){
  'use strict';

  var els, cookieDate,
    doc = document,
    currentDate = new Date(),
    cookieName = 'cookiebanner-accepted';

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){
    $( document ).on( 'click', '[data-hide-cookies]', hide );
  }



  /**
   * DOM queries
   * @method
  **/
  function dom(){
    els = {
      banner: document.getElementById( 'cookies' )
    };
  }

  /**
   * Show cookies banner if needed
   * @method
  **/
  function display(){
    var hidden = !!(new RegExp('(?:^|;\\s*)' + encodeURIComponent( cookieName ).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=')).test(doc.cookie);
    var menuDesktop = document.getElementById( 'menuDesktop' );
    var menuMobile = document.getElementById( 'menuMobile' );
    var cookieHeight =  $("#cookies").height();
    var navMobileHeight =  $("#menuMobile").height() + $(".bottom-menu").height();
    var modalBookingHeight =  $("#menuMobile").height();
    //si cookies acceptés
    if( hidden ){

      els.banner.parentNode.removeChild( els.banner );

      if (menuDesktop != null) {
        if (!menuDesktop.classList.contains('sticky')) {
          menuDesktop.classList.add('sticky');
        }

      }

      if (menuMobile != null) {

        if (!menuMobile.classList.contains('sticky')) {
          menuMobile.classList.add('sticky');
        }
        $('header[role="banner"] .header-mobile .middle-menu').css("height", "calc(100vh - "+ navMobileHeight + "px)");
      }
    }
    else{ //sinon cookies a afficher
      els.banner.setAttribute( 'aria-hidden', 'false' );

      if (menuDesktop != null) {
        menuDesktop.classList.remove('sticky');
      }

      if (menuMobile != null) {
        menuMobile.classList.remove('sticky');
        $('header[role="banner"].sticky').css("top", cookieHeight + "px");
        $('header[role="banner"] .header-mobile .middle-menu').css("height", "calc(100vh - "+(cookieHeight + navMobileHeight) + "px)");
        if($(".reservation-bar-mobile") != null){
          $(".reservation-bar-mobile .modal").css("padding-top", (cookieHeight + modalBookingHeight) + 'px');
        }
      }
    }
  }

  /**
   * Hides cookies
   * @method
  **/
  function hide(){
    cookieDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1)); // set date 1 year ahead
    cookieDate = cookieDate.toUTCString();
    doc.cookie = encodeURIComponent( cookieName ) + '=' + encodeURIComponent( 'true' ) + '; expires=' + cookieDate;
    var navMobileHeight =  $("#menuMobile").height() + $(".bottom-menu").height();
    var modalBookingHeight =  $("#menuMobile").height();

    $( els.banner ).slideUp( 300, function(){
      els.banner.parentNode.removeChild( els.banner );
      var menuDesktop = document.getElementById( 'menuDesktop' );
      if (menuDesktop != null) {
        if (!menuDesktop.classList.contains('sticky')) {
          menuDesktop.classList.add('sticky');
          if(document.getElementsByClassName('anchor-nav sticky').length > 0)
          {
            var headerPosition = document.getElementById('menuDesktop').getBoundingClientRect().bottom;
            document.getElementsByClassName('anchor-nav sticky')[0].setAttribute('style', 'top:' + headerPosition + 'px');
          }
        }
      }
      if (menuMobile != null) {
        if (!menuMobile.classList.contains('sticky')) {
          menuMobile.classList.add('sticky');
        }
        $('header[role="banner"] .header-mobile .middle-menu').css("height", "calc(100vh - "+navMobileHeight+"px)");
        $(".reservation-bar-mobile .modal").css("padding-top", modalBookingHeight + 'px');
        $('header[role="banner"].sticky').css("top", "0");
      }
      $.publish( 'scrollRefresh' );
    });
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();

    if( !els.banner ){
      return;
    }
    bind();

    display();
  }


  $.subscribe( 'app.start', init );

  return{};
}

module.exports = Cookies;
