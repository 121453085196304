
/**
 * Manages AnchorNav
 * @constructor
 */
function BookingBar() {
  'use strict';
  var _calendarConfig;

  const typeLangage = {
    francophone: "francophone",
    anglophone: "anglophone",
    allemand: "allemand",
  }

  var _typelangage = getLanguageContext();

  if (_typelangage == typeLangage.francophone){
    _calendarConfig = {
      previousMonth: 'Mois précédent',
      nextMonth: 'Mois suivant',
      months: ['Janvier', 'Février', 'Mars', 'Avri', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
    };
  } else if (_typelangage == typeLangage.anglophone) {
    _calendarConfig = {
      previousMonth: 'Previous',
      nextMonth: 'Next',
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      weekdays: ['Sunday ', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', ' Friday', 'Saturday'],
      weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    };  
  } else if (_typelangage == typeLangage.allemand){
    _calendarConfig = {
      previousMonth: 'früher',
      nextMonth: 'folgendes',
      months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      weekdays: ['Sonntag ', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', ' Freitag', 'Samstag'],
      weekdaysShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam']
    };  
  }


  var _frDateFormat = "DD/MM/YYYY";
  var aspDateInputFormat = "YYYY-MM-DD";
  var wrapper,
    els = {},
    mobile,
    data = {
      mq: window.matchMedia('(min-width: 62.5rem)'),
      pos: null,
      delta: 0,
      direction: 'down',
      navTop: 0
    };

  function customDatepickerStyle() {
    if ($(".barre_reservation")) {
      $(".pika-single").addClass("datepicker-reservation-bar");
    }
  }

  function countPassengers(ismobile) {
    var $total = 1;
    if (ismobile) {
      $total = +$("#number_1").val() + +$("#number_2").val() + +$("#number_3").val();
      displaySum($total);
    }
    else {
      $total = +$("#number1").val() + +$("#number2").val() + +$("#number3").val();
      displaySum($total);
    }
    //choix passagers//
    $(".type-passenger input").on("click", function () {
      $("#pas").html($("input:checked").val());
    });
    //bouton counter passagers//
    $('.minus').on("click", function () {
      var $input = $(this).parent().find('input');
      var count = parseInt($input.val()) - 1;
      count = count < 0 ? 0 : count;
      $input.val(count);
      $input.trigger("change");
      if (ismobile) {

        $total = +$("#number_1").val() + +$("#number_2").val() + +$("#number_3").val();
        displaySum($total);
      }
      else {
        $total = +$("#number1").val() + +$("#number2").val() + +$("#number3").val();
        displaySum($total);
      }
      return false;
    });
    $('.plus').on("click", function () {
      var $input = $(this).parent().find('input');
      var inputId = $input.attr("id");

      if (ismobile) {
        var childCount = parseInt($("#number_2").val()) + parseInt($("#number_3").val());
        if (childCount < 10 || inputId == "number_1") {
          $input.val(parseInt($input.val()) + 1);
          $input.trigger("change");
          $total = +$("#number_1").val() + +$("#number_2").val() + +$("#number_3").val();
          displaySum($total);
        }

      }
      else {
        var childCount = parseInt($("#number2").val()) + parseInt($("#number3").val());
        if (childCount < 10 || inputId == "number1") {
          $input.val(parseInt($input.val()) + 1);
          $total = +$("#number1").val() + +$("#number2").val() + +$("#number3").val();
          displaySum($total);
        }
      }
      return false;
    });
  }

  function getLanguageContext() {
    if (window.location.href.toLowerCase().includes("/de-de")) {
      return typeLangage.allemand;
    } else if (window.location.href.toLowerCase().includes("/fr-fr") || window.location.href.toLowerCase().includes("/fr-ca")) {
      return typeLangage.francophone;
    } else {
      return typeLangage.anglophone;
    }
  }
  
  function initToggle() {

    //Affichage liste activites //
    $('#span-activitiy-menu').on("click", function () {
      $('#activitiy-menu').toggle();
    });

    //Affichage liste passagers //
    $('#span-passengers-menu').on("click", function () {
      $("#passengers-menu").toggle();
    });

    //Affichage liste range bar //
    $('#span-range-bar').on("click", function () {
      $("#range-bar").toggle();
    });

    //Affichage liste type de cuisine //
    $('#span-typeFood-menu').on("click", function () {
      $("#typeFood-menu").toggle();
    });

    //Affichage liste gamme de prix //
    $('#span-typeFoodPrice-menu').on("click", function () {
      $("#typeFoodPrice-menu").toggle();
    });

    //Affichage liste services restaurant //
    $('#span-services-menu').on("click", function () {
      $("#services-menu").toggle();
    });

    // Affiche recherche affinée
    $('#equipment-span').on('click', function () {
      $("#divSearchBarEquipment #equipment-menu").toggle();
    });

    // Affiche recherche affinée
    $('#divSearchBarFilter').on('click', function () {
      $("#divSearchBarFilter #filter").toggle();
    });

    // Affiche recherche affinée
    $('#lnkAffinerRecherche').on('click', function () {
      $("#moreCriteria").toggle();
    });

    //Affichage liste type établissement //
    $('#establishmentType-span').on("click", function () {
      $("#establishmentType-menu").toggle();
    });



    $('.second-bloc .first-span').on("click", function (e) {
      //updateDisplayRangePrice();
      //e.preventDefault();
      e.stopPropagation();
      $(".max-layer").not($(this).next()).hide();
      $(".dropdown-mobile").not($(this).next()).hide();
      $(this).next().toggle();
    });


    //Affichage liste equipements //
    $('#span-equipement').on("click", function () {
      $(".max-layer #equipment-menu").show();
      var height_layer = $(".max-layer #equipment-menu").height();
      $(".select-equipments .max-layer").css('height', height_layer + 'px');
    });

    //Affichage liste filter//
    $('#span-filters').on("click", function () {
      $(".max-layer #filter").show();
      var height_layer = $(".max-layer #filter").height();
      $(".filter-equipments .max-layer").css('height', height_layer + 'px');
    });

    //Affichage liste type cuisine //
    $('#span-typeFood').on("click", function () {
      $(".max-layer #typeFoodMobile-menu").show();
      var height_layer = $(".max-layer #typeFoodMobile-menu").height();
      $(".select-equipments .max-layer").css('height', height_layer + 'px');
    });

    //Affichage liste food price//
    $('#span-priceRange').on("click", function () {
      $(".max-layer #priceRangeMobile-menu").show();
      var height_layer = $(".max-layer #priceRangeMobile-menu").height();
      $(".filter-equipments .max-layer").css('height', height_layer + 'px');
    });

    // Affiche recherche affinée
    $('#lnkMoreCriteriaMob').on('click', function () {
      $(".max-layer #moreCriteriaMobile").show();
      var height_layer = $(".max-layer #moreCriteriaMobile").height();
      $(".affinerRecherche .max-layer").css('height', height_layer + 'px');
    });

    $('.dropdown-mobile').click(function (e) {
      e.stopPropagation();
    });

    $(document).click(function () {
      // $('.dropdown-mobile').hide();
    });

    $('.close').click(function () {
      $('#myModal').hide();
      $('body').css('overflow','auto');
    });

    //$(".form-search").hide();
    $('#hide-form').on("click", function () {
      $("#myModal").toggle();
      $('body').css('overflow','hidden');
    });


    // Fermeture des sous menus quand on click en dehors des sous menu
    window.addEventListener('click', function (e) {
      if ((document.getElementById('filter') != null) && (document.getElementById('divSearchBarFilter') != null)) {
        if (!(document.getElementById('filter').contains(e.target) || document.getElementById('divSearchBarFilter').contains(e.target))) {
          if ($("#filter").is(":visible")) {
            $("#filter").hide();
          }
        }
      }

      if ((document.getElementById('equipment-menu') != null) && (document.getElementById('divSearchBarEquipment') != null)) {
        if (!(document.getElementById('equipment-menu').contains(e.target) || document.getElementById('divSearchBarEquipment').contains(e.target))) {
          if ($("#equipment-menu").is(":visible")) {
            $("#equipment-menu").hide();
          }

        }
      }

      if ((document.getElementById('passengers-menu') != null) && (document.getElementById('divSearchBarPassenger') != null)) {
        if (!(document.getElementById('passengers-menu').contains(e.target) || document.getElementById('divSearchBarPassenger').contains(e.target))) {
          if ($("#passengers-menu").is(":visible")) {
            $("#passengers-menu").hide();
          }
        }
      }

      if ((document.getElementById('passengers-menu') != null) && (document.getElementById('passengersContainer') != null)) {
        if (!(document.getElementById('passengers-menu').contains(e.target) || document.getElementById('passengersContainer').contains(e.target))) {
          if ($("#passengers-menu").is(":visible")) {
            $("#passengers-menu").hide();
          }
        }
      }

      if ((document.getElementById('range-bar') != null) && (document.getElementById('divSearchBarPrice') != null)) {
        if (!(document.getElementById('range-bar').contains(e.target) || document.getElementById('divSearchBarPrice').contains(e.target))) {
          if ($("#range-bar").is(":visible")) {
            $("#range-bar").hide();
          }
        }
      }

      if ((document.getElementById('activitiy-menu') != null) && (document.getElementById('divSearchBarActivity') != null)) {
        if (!(document.getElementById('activitiy-menu').contains(e.target) || document.getElementById('divSearchBarActivity').contains(e.target))) {
          if ($("#activitiy-menu").is(":visible")) {
            $("#activitiy-menu").hide();
          }
        }
      }

      if ((document.getElementById('typeFood-menu') != null) && (document.getElementById('typeFoodContainer') != null)) {
        if (!(document.getElementById('typeFood-menu').contains(e.target) || document.getElementById('typeFoodContainer').contains(e.target))) {
          if ($("#typeFood-menu").is(":visible")) {
            $("#typeFood-menu").hide();
          }
        }
      }

      if ((document.getElementById('typeFoodPrice-menu') != null) && (document.getElementById('typeFoodPriceContainer') != null)) {
        if (!(document.getElementById('typeFoodPrice-menu').contains(e.target) || document.getElementById('typeFoodPriceContainer').contains(e.target))) {
          if ($("#typeFoodPrice-menu").is(":visible")) {
            $("#typeFoodPrice-menu").hide();
          }
        }
      }

      if ((document.getElementById('services-menu') != null) && (document.getElementById('servicesContainer') != null)) {
        if (!(document.getElementById('services-menu').contains(e.target) || document.getElementById('servicesContainer').contains(e.target))) {
          if ($("#services-menu").is(":visible")) {
            $("#services-menu").hide();
          }
        }
      }

      if (document.getElementById('moreCriteria') != null) {
        if (!(document.getElementById('moreCriteria').contains(e.target) || document.getElementById('lnkAffinerRecherche').contains(e.target))) {
          if ($("#moreCriteria").is(":visible")) {
            $("#moreCriteria").hide();
          }
        }
      }


      if ((document.getElementById('establishmentType-menu') != null) && (document.getElementById('divSearchBarEstablishmentType') != null)) {
        if (!(document.getElementById('establishmentType-menu').contains(e.target) || document.getElementById('divSearchBarEstablishmentType').contains(e.target))) {
          if ($("#establishmentType-menu").is(":visible")) {
            $("#establishmentType-menu").hide();
          }
        }
      }
    });
  }

  function updateDisplayRangePrice() {
    var currency = $("#bookingSearchContainer").data("currency");
    var topprice = (mobile) ? $("#TopPrice").val() : $("#TopPrice-desktop").val();
    var bottomprice = (mobile) ? $("#BottomPrice").val() : $("#BottomPrice-desktop").val();
    if (mobile) {
      $("#range").text(bottomprice + "-" + topprice + " " + currency);
    }
    else {
      $("#range-desktop").text(bottomprice + "-" + topprice + " " + currency);
    }
  }

  function displaySum(total) {
    //attention séparer mobile du desktop
    $("#total-passengers_1").html(total.toString().padStart(2, '0'));
    $("#total-passengers").html(total.toString().padStart(2, '0'));
  }


  function selectReservationType() {
    //choix type activitie//
    $(".type_activity input").on("click", function () {
      $("#type-activity").val($(this.parentNode).children('label').text());
      $("#type-activity-hidden").val($(this).val());
      displayCritiria();
      // $("#activitiy-menu").hide();
    });
  }

  function selectTypeFood() {
   if ($("input[name='FoodId']:checked").val()) {
     $("#type-food").val($("input[name='FoodId']:checked").first().parent().children('label').text());
    }

    //choix type de cuisine
    $(".type_typeFood input").on("click", function () {
      $("#type-food").val($(this.parentNode).children('label').text());
      $("#type-food-hidden").val($(this).val());
      $("#foodInputRestaurant").val($(this).val());
    });
  }

  function selectTypeFoodPrice() {
    if ($("input[name='PriceRange']:checked").val()) {
      $("#type-foodPrice").val($("input[name='PriceRange']:checked").first().parent().children('label').text());
    }

    //choix gamme de prix
    $(".type_typeFoodPrice input").on("click", function () {
      $("#type-foodPrice").val($(this.parentNode).children('label').text());
      $("#type-foodPrice-hidden").val($(this).val());
      $("#priceRangeInput").val($(this).val())
    });
  }

  function loadDatepicker() {
    //Calendrier Pikaday //
    var _domDateStart = document.getElementById('depart');
    var _domDateEnd = document.getElementById('return');



    if (_domDateStart != null && _domDateEnd != null) {

      var startDate,
        endDate,
        updateStartDate = function () {
          startPicker.setStartRange(startDate);
          endPicker.setStartRange(startDate);
          // endPicker.setMinDate(startDate);
        },
        updateEndDate = function () {
          startPicker.setEndRange(endDate);
          // startPicker.setMaxDate(endDate);
          endPicker.setEndRange(endDate);
        },
        startPicker = new Pikaday({
          field: _domDateStart,
          format: _frDateFormat,
          minDate: new Date(),
          maxDate: new Date().setMonth(new Date().getMonth() + 24),
          i18n: _calendarConfig,
          onSelect: function () {
            startDate = this.getDate();
            $('#fromDateInputAccomodations').val(moment(startDate).format(aspDateInputFormat));
            endPicker.setMinDate(startDate);
            endPicker.setEndRange(null);
            endPicker.setDate(null);
            updateStartDate();
            $(".date-wrap #return").trigger("click");
          }
        }),
        endPicker = new Pikaday({
          field: _domDateEnd,
          format: _frDateFormat,
          minDate: new Date(),
          maxDate: new Date().setMonth(new Date().getMonth() + 24),
          i18n: _calendarConfig,
          onOpen: function () {
            if (endDate == null)
              endPicker.gotoDate(startDate);
          },
          onSelect: function () {
            endDate = this.getDate();
            $("#toDateInputAccomodations").val(moment(endDate).format(aspDateInputFormat));
            updateEndDate();
          }
        }),
        _startDate = startPicker.getDate(),
        _endDate = endPicker.getDate();

      if (_startDate) {
        startDate = _startDate;
        updateStartDate();
      }

      if (_endDate) {
        endDate = _endDate;
        updateEndDate();
      }
    }
  }

  function LoadDatePickerMobile() {
    //Mobile
    var _domDateStartMobile = document.getElementById('FromMobile');
    var _domDateEndMobile = document.getElementById('ToMobile');

    if (_domDateStartMobile != null && _domDateEndMobile != null) {

      var startDateMobile,
        endDateMobile,
        updateStartDateMobile = function () {
          startPickerMobile.setStartRange(startDateMobile);
          endPickerMobile.setStartRange(startDateMobile);
        },
        updateEndDateMobile = function () {
          startPickerMobile.setEndRange(endDateMobile);
          endPickerMobile.setEndRange(endDateMobile);
        },
        startPickerMobile = new Pikaday({
          field: _domDateStartMobile,
          format: _frDateFormat,
          minDate: new Date(),
          maxDate: new Date().setMonth(new Date().getMonth() + 24),
          i18n: _calendarConfig,
          onSelect: function () {
            startDateMobile = this.getDate();
            $('#fromDateInputAccomodations').val(moment(startDateMobile).format(aspDateInputFormat));
            endPickerMobile.setMinDate(startDateMobile);
            endPickerMobile.setEndRange(null);
            endPickerMobile.setDate(null);
            updateStartDateMobile();
            $(".date-wrap #ToMobile").trigger("click");
          }
        }),
        endPickerMobile = new Pikaday({
          field: _domDateEndMobile,
          format: _frDateFormat,
          minDate: new Date(),
          maxDate: new Date().setMonth(new Date().getMonth() + 24),
          i18n: _calendarConfig,
          onOpen: function () {
            if (endDateMobile == null)
              endPickerMobile.gotoDate(startDateMobile);
          },
          onSelect: function () {
            endDateMobile = this.getDate();
            $("#toDateInputAccomodations").val(moment(endDateMobile).format(aspDateInputFormat));
            updateEndDateMobile();
          }
        }),
        _startDateMobile = startPickerMobile.getDate(),
        _endDateMobile = endPickerMobile.getDate();

      if (_startDateMobile) {
        startDateMobile = _startDateMobile;
        updateStartDateMobile();
      }

      if (_endDateMobile) {
        endDateMobile = _endDateMobile;
        updateEndDateMobile();
      }
    }
  }

  function autocompleteLocation(countryList, ismobile) {
    /*An array containing all the country names */
    var countries = ["Toute l'ile"];
    if (countryList != null) {
      countries = countryList;
    }

    /*initiate the autocomplete function on the "myInput" element, and pass along the countries array as possible autocomplete values:*/
    var myInput = (ismobile) ? document.getElementById("span-locations-menu-mobile") : document.getElementById("span-locations-menu");

    if (myInput != null) {
      autocomplete(myInput, countries);
    }
  }

  function checkBasseTerre(locationName) {
    return locationName == "Commune de Basse-Terre";
  }

  function autocomplete(inp, arr) {
    /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/
    var currentFocus;
    var maxNumber = 0;

    // if (inp != null) {
    /*execute a function when someone writes in the text field:*/
    inp.addEventListener("input", function (e) {
      var a, b, i, val = this.value;
      /*close any already open lists of autocompleted values*/
      closeAllLists();
      if (!val) { return false; }
      currentFocus = -1;
      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("DIV");
      a.setAttribute("id", this.id + "autocomplete-list");
      a.setAttribute("class", "autocomplete-items dropdown");
      /*append the DIV element as a child of the autocomplete container:*/
      this.parentNode.appendChild(a);
      /*hide div if no result*/
      a.hidden = true;

      // update value of array if not null from binded data on html element (.cshtml)
      var countryFromHtmlElement = $("#bookingSearchContainer").data("countryList");

      if (countryFromHtmlElement !== null && countryFromHtmlElement !== undefined) {

        if (_typelangage == typeLangage.anglophone) {
          const index = countryFromHtmlElement.findIndex(checkBasseTerre);
          if (index != -1) {
            countryFromHtmlElement[index] = "City Of Basse-Terre";
          }
        }

        arr = countryFromHtmlElement;
      }

      maxNumber = 0;
      /*for each item in the array..
      for (i = 0; i < 6; i++) {*/
      for (i = 0; i < arr.length; i++) {
        /*check if the item includes the letters in the text field value:*/
        if (arr[i].toUpperCase().includes(val.toUpperCase())) {
          maxNumber++;
          a.hidden = false;
          /*create a DIV element for each matching element:*/
          b = document.createElement("DIV");
          /*make the matching letters bold:*/
          b.setAttribute("class", "pdg-btm-10");
          b.innerHTML = "<img src=\"/Statics/B2C/img/desktop/2x/icon-pin.png\">";
          b.innerHTML += "<strong>" + arr[i].substr(0, val.length) + "</strong>";
          b.innerHTML += arr[i].substr(val.length);
          /*insert a input field that will hold the current array item's value:*/
          b.innerHTML += "<input type='hidden' value=\"" + arr[i] + "\">";
          /*execute a function when someone clicks on the item value (DIV element):*/
          b.addEventListener("click", function (e) {
            /*insert the value for the autocomplete text field:*/
            inp.value = this.getElementsByTagName("input")[0].value;
            /*close the list of autocompleted values,
            (or any other open lists of autocompleted values:*/
            closeAllLists();
          });
          a.appendChild(b);
          if (maxNumber == 5) {
            return null; break;
          }
        }
      }
    });
    // }

    function closeAllLists(elmnt) {
      /*close all autocomplete lists in the document,
      except the one passed as an argument:*/
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        if (elmnt != x[i] && elmnt != inp) {
          x[i].parentNode.removeChild(x[i]);
        }
      }
    }

    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {
      closeAllLists(e.target);
    });
  }

  // Affichage des diff??rents crit?��re de recherche
  function displayCritiria() {
    switch ($("#type-activity-hidden").val()) {
      case 'activitie':
        $("#passengersContainer").show();
        $("#datesContainer").hide();
        $("#typeFoodContainer").hide();
        $("#typeFoodPriceContainer").hide();
        break;
      case 'restaurant':
        $("#passengersContainer").hide();
        $("#datesContainer").hide();
        $("#typeFoodContainer").show();
        $("#typeFoodPriceContainer").show();
        break;
      case 'accomodations' :
        $("#passengersContainer").show();
        $("#datesContainer").show();
        $("#typeFoodContainer").hide();
        $("#typeFoodPriceContainer").hide();
    }
  }

  function priceUpdate() {
    var bottomprice = "0€";
    var topprice = "1000€";
    if (mobile) {
      $("#range").text(bottomprice + " - " + topprice);
    }
    else {
      $("#range-desktop").text(bottomprice + "-" + topprice);
    }
    $(document).on("ready", function () {
      if (mobile) {
        bottomprice = $(".reservation-bar-mobile .noUi-handle-lower .noUi-tooltip").html();
        topprice = $(".reservation-bar-mobile .noUi-handle-upper .noUi-tooltip").html();
        $("#range").text(bottomprice + " - " + topprice);

        $(".reservation-bar-mobile .noUi-handle-lower .noUi-tooltip").on('DOMSubtreeModified', function () {
          bottomprice = $(".reservation-bar-mobile .noUi-handle-lower .noUi-tooltip").html();
          $("#range").text(bottomprice + " - " + topprice);
        });

        $(".reservation-bar-mobile .noUi-handle-upper .noUi-tooltip").on('DOMSubtreeModified', function () {
          topprice = $(".reservation-bar-mobile .noUi-handle-upper .noUi-tooltip").html();
          $("#range").text(bottomprice + " - " + topprice);
        });
      }
      else {
        bottomprice = $(".search_bar .noUi-handle-lower .noUi-tooltip").html();
        topprice = $(".search_bar .noUi-handle-upper .noUi-tooltip").html();
        $("#range-desktop").text(bottomprice + "-" + topprice);
        $(".search_bar .noUi-handle-lower .noUi-tooltip").on('DOMSubtreeModified', function () {
          bottomprice = $(".search_bar .noUi-handle-lower .noUi-tooltip").html();
          $("#range-desktop").text(bottomprice + "-" + topprice);
        });

        $(".search_bar .noUi-handle-upper .noUi-tooltip").on('DOMSubtreeModified', function () {
          topprice = $(".search_bar .noUi-handle-upper .noUi-tooltip").html();
          $("#range-desktop").text(bottomprice + "-" + topprice);
        });
      }
    });
  }


  function init() {
    wrapper = document.body;
    if (!wrapper) {
      return;
    }
    if ($(".barre_reservation")) {
      selectReservationType();
      displayCritiria();
      selectTypeFood();
      selectTypeFoodPrice();
      LoadDatePickerMobile();
      initToggle();
      var lc = ["Toute l'ile"];
      loadDatepicker();
      customDatepickerStyle();
      mobile = !data.mq.matches;
      priceUpdate();
      if (mobile) {
        autocompleteLocation(lc, true);
        countPassengers(true);
        $(".barre_reservation .dropdown").hide();
        $(".barre_reservation .dropdown-mobile").hide();
        $(".barre_reservation .max-layer").hide();
      }
      else {
        autocompleteLocation(lc, false);
        countPassengers(false);
        $(".barre_reservation .dropdown").hide();
      }
    }
  }
  $.subscribe('app.start', init);

  return {};
}


module.exports = BookingBar;
