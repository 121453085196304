/* global $ */

/**
 * Prevent ios to scroll the parent when done scrolling the current element
**/

function testScrollable( e ){
  e.currentTarget.allowUp = (e.currentTarget.scrollTop > 0);
  e.currentTarget.allowDown = (e.currentTarget.scrollTop < e.currentTarget.scrollHeight - e.currentTarget.clientHeight);
  e.currentTarget.prevTop = null;
  e.currentTarget.prevBot = null;
  e.currentTarget.lastY = e.originalEvent.changedTouches[0].pageY;
}

function cancelScroll( e ){
  var up = (e.originalEvent.changedTouches[0].pageY > e.currentTarget.lastY), down = !up;

  e.currentTarget.lastY = e.originalEvent.pageY;

  if( (up && e.currentTarget.allowUp) || (down && e.currentTarget.allowDown) ){
    e.stopPropagation();
  }
  else{
    e.preventDefault();
  }
}

function resetScroll( e ){
  e.currentTarget.allowUp = null;
  e.currentTarget.allowDown = null;
  e.currentTarget.prevTop = null;
  e.currentTarget.prevBot = null;
  e.currentTarget.lastY = null;
}

var els = [
  '#popin',
  '#popin article'
].join();

if( Modernizr.ios ){

  $( document.body )
    .on('touchstart', els, testScrollable )
    .on('touchmove', els, cancelScroll )
    .on('touchend touchcancel', els, resetScroll );
}
