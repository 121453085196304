/* global module, $ */

/**
 * Handle Offers ajax links
 * @constructor
 */
function Load( site ){
  'use strict';

  var els;

  /**
   * Set up all event listeners
   * @method
  **/
  function bind(){

    $( document ).on( 'click', '[data-ajax]', filter );
  }

  /**
   * DOM queries
   * @method
  **/
  function dom() {
    els = [].slice.call( document.querySelectorAll( '[data-ajax]' ));
  }

  function filter( e ){
    e.preventDefault();
    var link = e.currentTarget,
      url = link.href,
      id = link.getAttribute( 'data-ajax' ),
      target = document.getElementById( id ),
      $target = $( target );

    if( link.classList.contains( 'on' )){
      return;
    }

    $( '[data-ajax="' + id + '"].on' ).removeClass( 'on' );

    var $loader = $( '<div class="loader"></div>' );
    $loader
      .appendTo( target )
      .velocity({
        opacity: 1
      });

    $.ajax({
      'url': url
    })
      .done( function( data ){

        link.classList.add( 'on' );

        $target.velocity(
          {
            opacity: 0
          },
          {
            complete: function(){

              target.style.height = target.offsetHeight + 'px';

              var sliders = target.querySelectorAll( '.slick-initialized' );
              if( sliders && sliders.length ){
                $( sliders ).slick( 'unslick' );
              }
              $target.html( data );

              window.picturefill({
                elements: $( 'img', target )
              });

              if( site.sliders ){
                site.sliders.dom( target );
              }

              window.setTimeout( function(){
                target.style.height = '';

                $target.velocity({
                  opacity: 1
                }, 200 );
              });
            }
          }
        );
      })
      .fail( function(){
      })
      .always( function(){
        // console.log( arguments );
      });
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init(){
    dom();

    if( !els || !els.length ){
      return;
    }
    bind();
  }

  $.subscribe( 'app.start', init );

  return{};
}

module.exports = Load;
